import { Box, Skeleton } from '@mui/material';

const FlexDataLinkSkeleton = () => {
  return (
    <Box padding={2} data-testid="loading-container">
      <Box margin={2}>
        <Skeleton variant="rectangular" width={200} height={48} />
      </Box>
      <Box margin={2} display="flex" flexDirection="row" alignItems="center" gap={1}>
        <Skeleton variant="rectangular" width="30%" height={48} />
        <Skeleton variant="rectangular" width="30%" height={48} />
        <Skeleton variant="rectangular" width="30%" height={48} />
      </Box>
      <Box>
        <Box margin={2} display="flex" flexDirection="row" alignItems="center" gap={1}>
          <Skeleton variant="rectangular" width="20%" height={36} />
          <Skeleton variant="rectangular" width="20%" height={36} />
        </Box>
        <Box margin={2} display="flex" flexDirection="row" alignItems="center" gap={1}>
          <Skeleton variant="rectangular" width="20%" height={36} />
          <Skeleton variant="rectangular" width="20%" height={36} />
        </Box>
        <Box margin={2} display="flex" flexDirection="row" alignItems="center" gap={1}>
          <Skeleton variant="rectangular" width="20%" height={36} />
          <Skeleton variant="rectangular" width="20%" height={36} />
        </Box>
      </Box>
    </Box>
  );
};

export default FlexDataLinkSkeleton;
