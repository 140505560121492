import { useForm, SubmitHandler as SubmitHandlerT } from 'react-hook-form';
import { AddSeatsEntity } from 'services/api/seats/interfaces';

export type SeatsAddFormFieldValues = {
  collaboratorSeats?: number;
  userSeats?: number;
  accountId?: number;
};

export type SubmitHandler = SubmitHandlerT<SeatsAddFormFieldValues>;

export const transformSeatsFieldValuesToApiPayload = ({
  collaboratorSeats,
  userSeats,
  accountId,
}: SeatsAddFormFieldValues): AddSeatsEntity => {
  return {
    collaboratorSeats: collaboratorSeats || 0,
    userSeats: userSeats || 0,
    accountId: accountId || -1,
  };
};

export function useSeatsAddForm(loadedValues: SeatsAddFormFieldValues) {
  return useForm<SeatsAddFormFieldValues>({
    defaultValues: {
      collaboratorSeats: 0,
      userSeats: 0,
      accountId: -1,
    },
    mode: 'all',
    values: loadedValues || undefined,
  });
}
