import { z } from 'zod';
import { AnalyticsResponse } from './AnalyticsRepository';

export const validateAnalyticsResponse = (response: AnalyticsResponse) => {
  const AnalyticsResponseSchema = z.object({
    viewers: z.array(
      z.object({
        name: z.string().optional().nullable(),
        summary: z.object({
          totalViews: z.number(),
          totalViewTimeInSeconds: z.number(),
          lastViewedAt: z.string().datetime(),
          downloaded: z.number().optional(),
        }),
        email: z.string().email(),
        viewedSections: z.array(
          z.object({
            id: z.string(),
            name: z.string(),
            viewedTimeInSeconds: z.number(),
          })
        ),
      })
    ),
    updatedAt: z.string().datetime(),
  });

  AnalyticsResponseSchema.parse(response);
};
