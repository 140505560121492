import { Endpoints } from 'utils/endpoints';
import { apiClient } from 'services/api';

class FlexDataLinkRepositoryClass {
  public async getFlexDataLinks(documentId) {
    return await apiClient.get(Endpoints.getFlexDataLinks(documentId));
  }
}

export const FlexDataLinkRepository = new FlexDataLinkRepositoryClass();
