import { useRef } from 'react';

export enum ScrollDirectionsEnum {
  UP = 'up',
  DOWN = 'down',
}

const useScrollingDirection = (containerSelectorToTrack: string) => {
  const lastScrollTop = useRef<number>(0);
  const currentScrollDirection = useRef<ScrollDirectionsEnum>(ScrollDirectionsEnum.UP);

  const container = document.querySelector(containerSelectorToTrack);

  const getScrollDirection = () => {
    if (!container) return;

    const scrollTop = container.scrollTop;
    if (lastScrollTop.current < scrollTop) {
      currentScrollDirection.current = ScrollDirectionsEnum.DOWN;
    } else {
      currentScrollDirection.current = ScrollDirectionsEnum.UP;
    }
    lastScrollTop.current = scrollTop;
  };

  return {
    getCurrentScrollDirection: () => currentScrollDirection.current,
    startTrackingScrollDirection: () => container?.addEventListener('scroll', getScrollDirection),
    stopTrackingScrollDirection: () => container?.removeEventListener('scroll', getScrollDirection),
  };
};

export default useScrollingDirection;
