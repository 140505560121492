import { Page } from 'components/page';
import SectionHeader from 'components/sectionheader';
import Sidebar from 'components/sidebar';
import { useMediaQuery } from 'hooks/useMediaQuery';
import Alias from 'pages/alias';
import { NotificationsProvider } from 'components/notifications/NotificationsProvider';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { ApiUserIntegrationRepository } from 'services/integrations/repositories/implementations/ApiUserIntegrationRepository';

const apiUserIntegrationsRepository = new ApiUserIntegrationRepository();

const PageTemplateWrapper: React.FC = () => {
  const { isSmallerThanDesktop } = useMediaQuery();

  return (
    <NotificationsProvider>
      <Page
        sidenav={!isSmallerThanDesktop && <Sidebar mode="fixed" />}
        header={
          <>
            <Alias />
            {isSmallerThanDesktop && <Sidebar mode="collapsible" />}
            <SectionHeader title="Proposify" userIntegrationRepository={apiUserIntegrationsRepository} />
          </>
        }
      >
        <Outlet />
      </Page>
    </NotificationsProvider>
  );
};

export default PageTemplateWrapper;
