import { Grid, styled } from '@mui/material';
import { COLORS, SPACING } from '../../../../../../muiTheme/MuiDataGrid';
import TooltipInputRedux from '../components/TooltipInputRedux';
import { TableRowType } from 'components/editor/grid/reduxStore/table.types';

type SharedToggledInputProps = {
  children?: React.ReactNode;
  displayedValue: string;
  inputRefs: React.MutableRefObject<any>;
  inputType?: 'text' | 'number';
  isHidden?: boolean;
  isRowActive: boolean;
  onCellClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  onInputChange: (value: string) => void;
  row: TableRowType;
  value: string | number;
};

export const ToggledInputWrapper = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isHidden',
})(({ isHidden = false }: { isHidden?: boolean }) => ({
  backgroundColor: isHidden ? COLORS.greyedBackgroundColor : 'transparent',
  lineHeight: isHidden ? 'inherit' : 1,
  paddingRight: SPACING.sm,
  paddingLeft: SPACING.sm,
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

export const TitleTextWrapper = styled('span')({
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
});

const ToggledInput = ({
  children,
  displayedValue,
  inputRefs,
  inputType = 'number',
  isHidden = false,
  isRowActive,
  onCellClick,
  onInputChange,
  row,
  value,
}: SharedToggledInputProps) => {
  return (
    <ToggledInputWrapper
      className={isRowActive ? 'py-active-row' : ''}
      data-testid={`${row.title?.toLowerCase()}-row-tooltip-wrapper`}
      isHidden={isHidden}
      onClick={onCellClick}
    >
      {isRowActive ? (
        <TooltipInputRedux
          inputRefs={inputRefs}
          inputType={inputType}
          isHidden={isHidden}
          onInputChange={onInputChange}
          rowId={row.id}
          rowType={row.rowType}
          value={inputType === 'number' ? Number(value) : value}
        >
          {children}
        </TooltipInputRedux>
      ) : (
        <TitleTextWrapper>{displayedValue}</TitleTextWrapper>
      )}
    </ToggledInputWrapper>
  );
};

export default ToggledInput;
