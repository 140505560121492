import { Box, Button, Stack, ThemeProvider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';
import SettingsTheme from 'muiTheme/SettingsTheme';

interface ErrorPageProps {
  testId: string;
  title: string;
  content: string;
  image?: ReactNode;
  action?: {
    external?: boolean;
    text: string;
    path?: string | null;
  };
}

const ErrorPage = ({ testId, title, content, image, action }: ErrorPageProps) => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={SettingsTheme}>
      <Box
        width="100%"
        height="100%"
        bgcolor="primary.main"
        sx={{ boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.10)' }}
        data-testid={testId}
      >
        <Stack
          direction="column"
          width="100%"
          height="100%"
          maxWidth="552px"
          margin="0 auto"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          {image && (
            <Box mb={2} sx={{ width: '180px' }}>
              {image}
            </Box>
          )}
          <Typography variant="h6" mb="4px" color="primary.contrastText">
            {title}
          </Typography>
          <Typography variant="body1" mb={3} color="primary.contrastText">
            {content}
          </Typography>
          {action && (
            <Button
              data-testid="error-page-action"
              variant="contained"
              color="secondary"
              size="medium"
              sx={{ width: 'auto' }}
              onClick={() => {
                if (!action.path) {
                  navigate(-1);
                  return;
                }
                if (action.external) {
                  window.location.href = action.path;
                } else {
                  navigate(action.path);
                }
              }}
            >
              {action.text}
            </Button>
          )}
        </Stack>
      </Box>
    </ThemeProvider>
  );
};

export default ErrorPage;
