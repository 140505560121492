import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { NotificationsProvider } from 'components/notifications/NotificationsProvider';
import Notifications from 'components/notifications/Notifications';

const EmptyWithNotificationsTemplate: React.FC = () => {
  return (
    <Box className="empty-with-notifications-layout">
      <NotificationsProvider>
        <Outlet />
        <Notifications />
      </NotificationsProvider>
    </Box>
  );
};

export default EmptyWithNotificationsTemplate;
