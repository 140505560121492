import { Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IcoDelete from '../../icon/icons/IcoDelete';
import SectionTitleInput from './SectionTitleInput';

interface SectionToolbarProps {
  sectionId?: string;
  addButtonOnClick: () => void;
  deleteButtonOnClick?: () => void;
  enableDelete: boolean;
}
export function SectionToolbar({ sectionId, addButtonOnClick, deleteButtonOnClick, enableDelete }: SectionToolbarProps) {
  return (
    <>
      <Box className="section-topbar-wrapper">
        {sectionId && <SectionTitleInput sectionId={sectionId} />}
        <IconButton size="medium" onClick={addButtonOnClick}>
          <AddIcon data-testid="section-add-icon" fill={'none'} stroke={'none'} color={'primary'} />
        </IconButton>
        <IconButton
          disabled={!enableDelete}
          color={'primary'}
          size="medium"
          onClick={() => deleteButtonOnClick && enableDelete && deleteButtonOnClick()}
        >
          <IcoDelete data-testid="section-delete-icon" color={'primary'} fill={'black'} stroke={'none'} />
        </IconButton>
      </Box>
    </>
  );
}
