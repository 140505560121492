import { useQuery } from 'react-query';
import { ApiActivitiesRepository } from 'services/activities/ApiActivitiesRepository';
import { useInsightsContext } from '../InsightsProvider';

const REFRESH_INTERVAL_IN_MS = 60 * 1000;

export function useQueryActivities() {
  const { setError, documentId } = useInsightsContext();
  const { data, isLoading } = useQuery(['activities', documentId], () => ApiActivitiesRepository.getActivities(documentId), {
    refetchInterval: REFRESH_INTERVAL_IN_MS,
    enabled: !!documentId,
    onError: () => setError('activitiesNotLoaded', true),
  });

  const activities = !data?.activities ? [] : data.activities;
  return {
    activities,
    isLoading,
  };
}
