import { Theme } from '@mui/material/styles';
import { Components } from '@mui/material/styles/components';

export const MuiTypography: Components<Theme>['MuiTypography'] = {
  styleOverrides: {
    root: {
      fontFamily: 'proxima-nova',
    },
  },
};
