import { useTranslation } from 'react-i18next';
import ErrorPage from './ErrorPage';
import NoAccess from './images/no-access-icon.png';

const NoAccessDocumentErrorPage = () => {
  const { t } = useTranslation();

  return (
    <ErrorPage
      testId="no-access-document-error-page"
      title={t('errors.no_access_document.title')}
      content={t('errors.no_access_document.content')}
      image={<img src={NoAccess} width="100%" />}
      action={{
        path: '/documents',
        text: t('errors.no_access_document.action'),
      }}
    />
  );
};

export default NoAccessDocumentErrorPage;
