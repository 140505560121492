import React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FlexDataLinkDataGrid from './FlexDataLinkDataGrid';
import { ObjectType, FlexDataLink, TabPanelProps } from '../types';

function FlexDataLinkContainerTabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`flex-data-tabpanel-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const MemoizedTabPanel = React.memo(FlexDataLinkContainerTabPanel);

interface ObjectTypeTabsProps {
  parentDataSourceType: string;
  objectTypes: ObjectType[];
  flexDataLinks: FlexDataLink[];
}

const ObjectTypeTabs: React.FC<ObjectTypeTabsProps> = ({ parentDataSourceType, objectTypes, flexDataLinks }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {objectTypes.map((objectTypeObject) => (
            <Tab key={objectTypeObject.objectTypeLabel} label={objectTypeObject.objectTypeLabel} />
          ))}
        </Tabs>
      </Box>
      {objectTypes.map((objectTypeObject, index) => (
        <MemoizedTabPanel key={objectTypeObject.objectTypeLabel} value={value} index={index}>
          <FlexDataLinkDataGrid
            parentDataSourceType={parentDataSourceType}
            parentObjectType={objectTypeObject.objectTypeLabel}
            flexDataLinks={flexDataLinks}
          />
        </MemoizedTabPanel>
      ))}
    </Box>
  );
};

export default ObjectTypeTabs;
