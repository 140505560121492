import React, { useState, useRef, KeyboardEvent, useEffect } from 'react';
import { Col, Layout, Row } from 'antd';
import DOMPurify from 'dompurify';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { ProfileActions, HelpButton, Paragraph, Tooltip, openNotification, Button } from 'components';
import PriceInput from 'components/priceInput';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';
import { ErrorCode } from 'services/socket/SocketEvents';
import './styles.less';
import SaveStatusText from '../shared/components/SaveStatus/SaveStatus';
import { setSaveStatus } from '../grid/reduxStore/saveStatusSlice';
import { EditorToolbar } from '../EditorMenu/EditorToolbar';
import { Chip } from '@mui/material';
import { useGetAssetById } from 'hooks/useGetAssetById';
import { useUpdateAssetMutation } from 'hooks/useUpdateAssetMutation';
import { AssetApiUpdateResponse } from 'services/repositories/implementations/ApiContentLibraryRepository';
import useCreateDocumentMutation from 'hooks/useCreateDocumentMutation';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'utils/urls';
import { useAppDispatch } from '../grid/reduxStore/Store';

const sanitizeTitle = (value: string) => {
  return DOMPurify.sanitize(value).substring(0, 99);
};

const blurOnEnter = (event: KeyboardEvent<HTMLInputElement>) => {
  if (event.key === 'Enter') {
    event.currentTarget.blur();
  }
};

interface Props {
  assetId: string;
}

export function TemplateEditorMenu({ assetId }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState<string>(t('editor.editor_menu.default_template_title'));
  const [documentPrice, setDocumentPrice] = useState<number | null>(0);
  const documentTitleRef = useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;
  const { mutate } = useUpdateAssetMutation();
  const { createDocument } = useCreateDocumentMutation();
  const { data: assetData } = useGetAssetById(assetId);
  const navigate = useNavigate();

  useEffect(() => {
    if (assetData?.name) {
      setTitle(assetData.name);
    }
    if (assetData?.price) {
      setDocumentPrice(assetData.price);
    }
  }, [assetData]);

  const generateDocumentHandle = async () => {
    if (!assetData) {
      return;
    }

    let documentId: string;
    try {
      const createdDocument = await createDocument({
        title: assetData.name,
        price: assetData.price,
        titleOfFirstSection: t('editor.default_content_section_title'),
        copyFromContentId: assetData.content_id,
      });
      documentId = createdDocument.documentId;
    } catch (error) {
      openNotification({
        type: 'error',
        title: t('editor.editor_menu.error_title'),
        description: t('editor.editor_menu.error_description'),
        placement: 'top',
      });
      return;
    }
    navigate(`${URLS.document}/${documentId}`);
  };

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const sanitizedValue = sanitizeTitle(event.target.value);
    setTitle(sanitizedValue);
  }

  const updateDocumentProperties = (payload: Omit<AssetApiUpdateResponse, 'assetId'>) => {
    dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVING }));
    mutate(
      { assetId, ...payload },
      {
        onSuccess: () => {
          dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVED }));
        },

        onError: (error: unknown) => {
          if (error instanceof AxiosError && error.response?.status === 423) {
            dispatch(setSaveStatus({ status: DocumentSaveStatus.NOT_SAVED, errCode: ErrorCode.LOCKED }));
          } else {
            openNotification({
              title: t('error'),
              description: t('document.menu.update_error'),
              type: 'error',
            });
          }
        },
      }
    );
  };

  const updateTitle = () => {
    const sanitizedTitle = sanitizeTitle(title);
    if (!sanitizedTitle.trim()) {
      const defaultTitle = t('editor.editor_menu.default_template_title');
      setTitle(defaultTitle);
      persistTitle({ name: defaultTitle });
    } else {
      persistTitle({ name: sanitizedTitle });
    }
  };

  const persistTitle = (titlePayload: object) => {
    updateDocumentProperties(titlePayload);
  };

  const updatePrice = () => {
    updateDocumentProperties({ price: documentPrice });
  };

  return (
    <Layout.Header className="editor-header">
      <Row justify="space-between" align="top" className="editor-menu-container">
        <Col className="editor-menu-container__column--left document-details">
          <Tooltip title={title} placement="bottom">
            <input
              ref={documentTitleRef}
              onKeyDown={blurOnEnter}
              className="document-title"
              maxLength={100}
              placeholder={t('editor.editor_menu.default_template_title')}
              onChange={onChange}
              onBlur={updateTitle}
              value={title}
            />
          </Tooltip>
          <Chip className="template-chip" label="Template" />
          <div className="document-status">
            $<PriceInput value={documentPrice ?? 0} onChange={setDocumentPrice} onBlur={updatePrice} />
            <Paragraph size="sm" className="timestamp-ml-xs">
              <SaveStatusText></SaveStatusText>
            </Paragraph>
          </div>
        </Col>
        <Col className="editor-menu-container__column--right">
          <div className="buttons-container">
            <Button variant="positive" type="primary" className="send-button" onClick={generateDocumentHandle}>
              {t('editor.editor_menu.generate_document')}
            </Button>
          </div>
          <HelpButton />
          <ProfileActions />
        </Col>
      </Row>
      <EditorToolbar />
    </Layout.Header>
  );
}
