import { ForwardedRef, forwardRef, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'components/editor/grid/reduxStore/Store';
import { selectContentTable, setSelectedRow, updatePricingTotal } from 'components/editor/grid/reduxStore/editorSlice';
import { setActiveTableSettingsPanel, setToggledDesignSettingModelType } from '../../../../grid/reduxStore/blockStyleSettingsSlice';

import { Box, styled } from '@mui/material';

import PricingTableTotalFooterRow from './PricingTableTotalFooterRow';
import CellDropdownWrapper from '../CellDropdownWrapper';
import ToggledInput from './ToggledInput';

import { updateTotalRowsWithSubtotalCalculation } from '../tableUtils/updateTotalRowsWithSubtotalCalculation';
import { setOfRowsForPricingTable } from '../defaultData';
import { isTaxOrDiscountRow } from '../../../../../../muiTheme/dataGridUtils';
import getTotalFooterRowValue from './getTotalFooterRowValue';
import { debounce } from 'lodash';

import { TableSettingsTypes } from '../../../../SidePanel/content/TableSettings/types';
import { TableType, TotalRowTypesIdentifier } from '../../../../grid/reduxStore/table.types';
import { SidePanelProviderContext } from '../../../../SidePanel/content';

import { useBlockContentChangedHandler } from '../../../../hooks/UseBlockContentChangedHandler';

type PricingTableTotalsFooterType = {
  isEditState?: boolean;
  sectionId: string;
  blockId: string;
  sumOfSubtotalColumn: number;
};

export const FORMATTER_KEY = 'price';
const TITLE_KEY = 'title';

export const FooterWrapper = styled(Box)({
  overflow: 'hidden',
  borderRadius: '0 0 5px 5px',
  borderColor: 'transparent',
  width: '100%',
});

export const PricingTableTotalsFooter = forwardRef(
  ({ isEditState = false, sectionId, blockId, sumOfSubtotalColumn }: PricingTableTotalsFooterType, ref: ForwardedRef<HTMLDivElement>) => {
    const dispatch = useAppDispatch();
    const { setToggledTableSettingsPanel } = useContext(SidePanelProviderContext);
    const { activeTableSettingsPanel } = useSelector((state: RootState) => state.blockStyleSettings);
    const isActiveFooterPanel = activeTableSettingsPanel === TableSettingsTypes.TABLE_FOOTER_ROWS;
    const blockContentChangedHandler = useBlockContentChangedHandler();
    const tableData = useSelector((state: RootState) => selectContentTable(state, sectionId, blockId)) || undefined;
    const { metadata, selectedRow, totalRows } = useSelector((state: RootState) => selectContentTable(state, sectionId, blockId));
    const totalTableRows = totalRows || setOfRowsForPricingTable;
    const selectedRowId = selectedRow?.id;
    const inputRefs = useRef({});
    const updatedRows = updateTotalRowsWithSubtotalCalculation(totalTableRows, sumOfSubtotalColumn);
    const pricingTableTotal = updatedRows?.find((row) => row.rowType === TotalRowTypesIdentifier.TOTAL)?.subtotal;

    const [clickedCell, setClickedCell] = useState<string | null>(null);
    const debouncedSave = useMemo(
      () =>
        debounce((newData: TableType) => {
          blockContentChangedHandler(blockId, sectionId, newData);
        }, 300),
      [blockId, sectionId]
    );

    const memoizedRows = useMemo(() => {
      return updatedRows?.map((row) => {
        const { isHidden, calculateBaseValue, cellConfigObject, displayedValue } = getTotalFooterRowValue(row);
        const { rowType, subtotal, id } = row;
        const rowValue = rowType === TotalRowTypesIdentifier.SUBTOTAL ? sumOfSubtotalColumn : subtotal;
        const isRowSelected = id === selectedRowId;
        const currencyOrPercentageSymbol = (cellConfigObject?.valueFormatter || '') as string;
        const isRowEditable = isEditState && isTaxOrDiscountRow(row);

        return {
          row,
          renderedProps: {
            isHidden,
            calculateBaseValue,
            cellConfigObject,
            displayedValue,
            rowValue,
            isRowSelected,
            currencyOrPercentageSymbol,
            isRowEditable,
          },
        };
      });
    }, [updatedRows, selectedRowId, isEditState, sumOfSubtotalColumn]);

    const handleCellClick = (e, row, cellType) => {
      if (!isEditState) return setClickedCell(null);
      setClickedCell(`${row.id}-${cellType}`);
    };

    const handleRowClick = (e, row) => {
      const isInput = e.target instanceof HTMLInputElement;
      const isActiveRow = selectedRow?.id === row.id;
      if ((isInput && isActiveRow) || !isEditState) return;

      if (isEditState) {
        if (row.id !== selectedRow?.id) {
          dispatch(setSelectedRow({ sectionId, blockId, row }));
        }
        setToggledTableSettingsPanel(null);
        dispatch(setToggledDesignSettingModelType({ type: null }));
        dispatch(setActiveTableSettingsPanel({ type: TableSettingsTypes.TABLE_FOOTER_ROWS }));
      }
    };

    const handleInputChange = (value: string, cellKey: typeof FORMATTER_KEY | typeof TITLE_KEY) => {
      const updatedRows = totalTableRows?.map((row) =>
        row.id === selectedRow?.id ? { ...row, [cellKey === FORMATTER_KEY ? 'price' : 'title']: value } : row
      );

      const newTableData: TableType = {
        ...tableData,
        totalRows: updatedRows,
        metadata: {
          ...metadata,
        },
      };

      debouncedSave(newTableData);
    };

    useEffect(() => {
      if (!isActiveFooterPanel) {
        dispatch(setSelectedRow({ sectionId, blockId, row: null }));
      }
    }, [isActiveFooterPanel]);

    useEffect(() => {
      return () => {
        debouncedSave.cancel();
      };
    }, [debouncedSave]);

    useEffect(() => {
      dispatch(updatePricingTotal({ sectionId, blockId, total: pricingTableTotal }));
    }, [dispatch, pricingTableTotal, sectionId, blockId]);

    return (
      <FooterWrapper ref={ref}>
        {memoizedRows?.map(({ row, renderedProps }) => {
          const { isHidden, calculateBaseValue, cellConfigObject, displayedValue, isRowEditable } = renderedProps;
          const { rowType, subtotal, id, title } = row;
          const rowValue = rowType === TotalRowTypesIdentifier.SUBTOTAL ? sumOfSubtotalColumn : subtotal;
          const isRowSelected = id === selectedRowId;
          const currencyOrPercentageSymbol = (cellConfigObject?.valueFormatter || '') as string;
          return (
            <PricingTableTotalFooterRow
              key={id}
              title={title}
              rowType={rowType as TotalRowTypesIdentifier}
              subtotalValue={rowValue}
              priceValue={calculateBaseValue}
              isRowSelected={isRowSelected}
              pricingDecimalPlaces={metadata?.pricingDecimalPlaces}
              onClick={(e) => handleRowClick(e, row)}
              calcInputComponent={
                <ToggledInput
                  displayedValue={displayedValue}
                  inputRefs={inputRefs}
                  isHidden={isHidden}
                  isRowActive={isRowEditable && clickedCell === `${row.id}-${FORMATTER_KEY}`}
                  onCellClick={(e) => handleCellClick(e, row, FORMATTER_KEY)}
                  onInputChange={(value) => handleInputChange(value, FORMATTER_KEY)}
                  row={row}
                  value={calculateBaseValue}
                >
                  <CellDropdownWrapper
                    blockId={blockId}
                    cellConfigObject={cellConfigObject}
                    data={row}
                    isHidden={isHidden}
                    sectionId={sectionId}
                    valueFormatter={currencyOrPercentageSymbol}
                  />
                </ToggledInput>
              }
              titleComponent={
                <ToggledInput
                  displayedValue={title}
                  inputRefs={inputRefs}
                  inputType="text"
                  isRowActive={isEditState && clickedCell === `${row.id}-${TITLE_KEY}`}
                  onCellClick={(e) => handleCellClick(e, row, TITLE_KEY)}
                  onInputChange={(value) => handleInputChange(value, TITLE_KEY)}
                  row={row}
                  value={title}
                />
              }
            />
          );
        })}
      </FooterWrapper>
    );
  }
);

PricingTableTotalsFooter.displayName = 'PricingTableTotalsFooter';
export default PricingTableTotalsFooter;
