import { useCurrentUser } from 'providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { useFormatRelativeDateTime } from '../../utils/useFormatRelativeDateTime';
import { useShowExactTime } from '../useShowExactTime';

type FormattedActivityDate = {
  dateOrRelativeDate: string;
  time: string;
};

export function useFormatActivityDate() {
  const { i18n } = useTranslation();
  const { data: currentUserInfo } = useCurrentUser();
  const { showExactTime } = useShowExactTime();
  const { formatRelativeDateTime } = useFormatRelativeDateTime();

  const userTimezone = currentUserInfo.timezone || 'UTC';

  const formatRelativeTime = (date: string): FormattedActivityDate => {
    return {
      dateOrRelativeDate: formatRelativeDateTime(date),
      time: '',
    };
  };

  const formatExactTime = (date: string): FormattedActivityDate => {
    const dateObj = new Date(date);
    return {
      dateOrRelativeDate: dateObj.toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric', timeZone: userTimezone }),
      time: dateObj.toLocaleTimeString(i18n.language || 'en-US', { hour: 'numeric', minute: 'numeric', timeZone: userTimezone }),
    };
  };

  return { formatActivityDate: showExactTime ? formatExactTime : formatRelativeTime };
}
