import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';

interface DataSourceSelectProps {
  dataSourceTypes: { dataSourceType: string; dataSourceName: string }[];
  onChange: (event: SelectChangeEvent) => void;
  selectedDataSourceType: string;
}

const DataSourceSelect: React.FC<DataSourceSelectProps> = ({ dataSourceTypes, onChange, selectedDataSourceType }) => {
  const isSingleDataSource = dataSourceTypes.length === 1;

  if (isSingleDataSource) {
    return <Typography sx={{ minWidth: 200, padding: 1 }}>{dataSourceTypes[0]?.dataSourceName}</Typography>;
  }

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel id="data-source-type-select-label">App</InputLabel>
      <Select
        labelId="data-source-type-select-label"
        id="data-source-type-select"
        value={selectedDataSourceType}
        label="App"
        onChange={onChange}
      >
        {dataSourceTypes.map(({ dataSourceType, dataSourceName }) => (
          <MenuItem key={dataSourceType} value={dataSourceType}>
            {dataSourceName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DataSourceSelect;
