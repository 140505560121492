import { useState } from 'react';
import { useQuery } from 'react-query';
import { TopLevelObjectTypeRepository } from 'services/flex-data/repositories/TopLevelObjectTypeRepository';
import { QueryKeys } from 'utils/queryKeys';
import { TopLevelObjectType, TopLevelObjectTypeResponse } from './types';

export const useTopLevelObjectType = () => {
  const [topLevelObjectTypes, setTopLevelObjectTypes] = useState<TopLevelObjectType[]>([]);
  const { isLoading, isError, status } = useQuery(
    QueryKeys.getFlexDataTopLevelObjectTypes,
    TopLevelObjectTypeRepository.getTopLevelObjectTypes,
    {
      onSuccess: (data: TopLevelObjectTypeResponse) => {
        setTopLevelObjectTypes(data.data);
      },
    }
  );
  return { isTopLevelObjectTypesLoading: isLoading, topLevelObjectTypes, isTopLevelObjectTypesError: isError, status };
};
