import { BlocksFromSectionsByDocumentIdApiResponse, SectionByDocumentIdApiResponse } from 'services/documents/DocumentRepository';
import { gridPageMinHeightInPixels, gridPixelSize } from '../editor/shared/gridConfig';
import { getEditorMaxHeight } from '../editor/GridDndEditor/gridHelper';
import { GridBlockType } from '../editor/shared/gridBlockType';
import { BlockSettings, ImageBlockSettings, TextBlockSettings } from '../editor/grid/reduxStore/saveHandlers';
import { BlockContent, BlocksContentCollection } from '../editor/grid/reduxStore/editorSlice';
import { PreviewBlock } from 'components/editor/GridDndEditor/Block/Preview/PreviewBlock';
import { editorConfig } from '../editor/helpers/config';
import FroalaEditor from 'react-froala-wysiwyg';
import { usePreviewSignatures } from '../../hooks/usePreviewSignatures';
import ImageComponent, { ImageDimension } from '../editor/GridDndEditor/Block/Image/GridImageBlock/ImageComponent';
import { PreviewTableBlock } from '../editor/GridDndEditor/Block/Preview/PreviewTableBlock';
import { getSignaturesMaxHeight } from '../editor/SidePanel/Signatures/SignatureHelper';
import PreviewSignatures from './PreviewSignatures';
import { SectionTitleTypography } from './SectionTitleTypography';
import { PREVIEW_SECTION_CONTAINER_CLASS } from './useTrackActiveSectionInPreview';

const getBlocksContentFromApiResponse = (
  gridBlocks: BlocksFromSectionsByDocumentIdApiResponse[]
): { gridBlocksContent: BlocksContentCollection; blockStyleMap: Map<string, BlockSettings> } => {
  const gridBlocksContent: BlocksContentCollection = {};
  const blockStyleMap: Map<string, BlockSettings> = new Map();
  for (const gridBlockElement of gridBlocks) {
    const blockContent: BlockContent = {
      content: gridBlockElement.htmlContent,
      type: gridBlockElement.type,
      blockConfig: {
        id: gridBlockElement.gridId,
        x: gridBlockElement.position.left_px,
        y: gridBlockElement.position.top_px,
        z: gridBlockElement.position.z_index,
        width: gridBlockElement.dimensions.width_px,
        height: gridBlockElement.dimensions.height_px,
      },
    };
    if (gridBlockElement.type === GridBlockType.TABLE) {
      blockContent.contentTable = gridBlockElement.content;
    }

    gridBlocksContent[gridBlockElement.gridId] = blockContent;

    // Add block settings to blockStyleMap
    blockStyleMap.set(gridBlockElement.gridId, {
      blockType: gridBlockElement.type,
      updatedAt: '',
      blockId: gridBlockElement.gridId,
      borderTop: gridBlockElement.blockSettings?.borderTop || 0,
      borderLeft: gridBlockElement.blockSettings?.borderLeft || 0,
      borderRight: gridBlockElement.blockSettings?.borderRight || 0,
      borderBottom: gridBlockElement.blockSettings?.borderBottom || 0,
      borderColor: gridBlockElement.blockSettings?.borderColor || '',
      borderRadius: gridBlockElement.blockSettings?.borderRadius || 0,
      backgroundColor: (gridBlockElement.blockSettings as TextBlockSettings)?.backgroundColor || '',
      updatedByUserId: gridBlockElement.blockSettings?.updatedByUserId || 0,
      opacity: (gridBlockElement.blockSettings as ImageBlockSettings)?.opacity || 100,
      imageAlt: (gridBlockElement.blockSettings as ImageBlockSettings)?.imageAlt || '',
      imageLink: (gridBlockElement.blockSettings as ImageBlockSettings)?.imageLink || '',
      paddingLeft: gridBlockElement.blockSettings?.paddingLeft || 0,
      paddingRight: gridBlockElement.blockSettings?.paddingRight || 0,
      paddingTop: gridBlockElement.blockSettings?.paddingTop || 0,
      paddingBottom: gridBlockElement.blockSettings?.paddingBottom || 0,
    });
  }
  return { gridBlocksContent, blockStyleMap };
};

const PreviewSections = ({
  documentStyle,
  documentId,
  sections,
  isDocumentLocked,
}: {
  documentStyle: React.CSSProperties;
  documentId: string;
  sections: SectionByDocumentIdApiResponse[];
  isDocumentLocked: boolean;
}) => {
  const { sectionsSignatures, setSignedSignatureInSection } = usePreviewSignatures(documentId);

  return (
    <>
      {sections.map((section, sectionIndex) => {
        const { gridBlocksContent, blockStyleMap } = getBlocksContentFromApiResponse(section.blocks);
        return (
          <div
            key={`preview-section-container-${section.id}`}
            id={section.id}
            className={PREVIEW_SECTION_CONTAINER_CLASS}
            data-section-id={section.id}
          >
            <SectionTitleTypography sectionId={section.id} sectionTitle={section.title} />
            <div
              key={`preview-section-${section.id}`}
              data-testid="grid-preview"
              className={`preview__editor__wrapper__grid section-${sectionIndex}`}
              style={{
                ...documentStyle,
                height: Math.max(
                  gridPageMinHeightInPixels,
                  getEditorMaxHeight(gridBlocksContent, getSignaturesMaxHeight(sectionsSignatures[section.id]))
                ),
              }}
            >
              <div className="preview__grid__blocks">
                {Object.values(gridBlocksContent).map((block) => {
                  if (block.type === GridBlockType.TEXT) {
                    const blockSettings = blockStyleMap.get(block.blockConfig.id);
                    if (!blockSettings) return;
                    const {
                      paddingLeft,
                      paddingTop,
                      paddingRight,
                      paddingBottom,
                      backgroundColor,
                      borderColor,
                      borderLeft,
                      borderRight,
                      borderTop,
                      borderBottom,
                      borderRadius,
                    } = blockSettings as unknown as TextBlockSettings;
                    return (
                      <PreviewBlock key={`preview-section-block-${block.blockConfig.id}`} gridBlock={block} gridSystemInPx={gridPixelSize}>
                        <div
                          className={block.blockConfig.id}
                          style={{
                            borderTop: `solid ${borderColor} ${borderTop}px`,
                            borderBottom: `solid ${borderColor} ${borderBottom}px`,
                            borderLeft: `solid ${borderColor} ${borderLeft}px`,
                            borderRight: `solid ${borderColor} ${borderRight}px`,
                            borderRadius: `${borderRadius}px`,
                            backgroundColor: backgroundColor,
                            paddingLeft: `${paddingLeft}px`,
                            paddingTop: `${paddingTop}px`,
                            paddingRight: `${paddingRight}px`,
                            paddingBottom: `${paddingBottom}px`,
                            height: '100%',
                          }}
                          data-testid={`test_${block.blockConfig.id}`}
                        >
                          <FroalaEditor
                            key={block.blockConfig.id}
                            tag="textarea"
                            config={{
                              ...editorConfig,
                              placeholderText: '',
                              editorClass: 'py-preview',
                              events: {
                                initialized() {
                                  (this as any).edit.off();
                                },
                              },
                            }}
                            model={block.content}
                          />
                        </div>
                      </PreviewBlock>
                    );
                  } else if (block.type === GridBlockType.IMAGE) {
                    const blockSettings = blockStyleMap.get(block.blockConfig.id);
                    if (!blockSettings) return;
                    const imageAlt = 'imageAlt' in blockSettings ? blockSettings.imageAlt : '';
                    const imageLink = 'imageLink' in blockSettings && blockSettings.imageLink ? 'https://' + blockSettings.imageLink : '';
                    const {
                      paddingLeft,
                      paddingTop,
                      paddingRight,
                      paddingBottom,
                      borderColor,
                      borderLeft,
                      borderRight,
                      borderTop,
                      borderBottom,
                      borderRadius,
                      opacity,
                    } = blockSettings as unknown as ImageBlockSettings;

                    const imageDimension: ImageDimension = {
                      width: block.blockConfig.width,
                      height: block.blockConfig.height,
                    };
                    return (
                      <PreviewBlock key={block.blockConfig.id} gridBlock={block} gridSystemInPx={gridPixelSize}>
                        {imageLink ? (
                          <a href={imageLink} target="_blank" rel="noopener">
                            <ImageComponent
                              style={{
                                borderTop: `solid ${borderColor} ${borderTop}px`,
                                borderBottom: `solid ${borderColor} ${borderBottom}px`,
                                borderLeft: `solid ${borderColor} ${borderLeft}px`,
                                borderRight: `solid ${borderColor} ${borderRight}px`,
                                borderRadius: `${borderRadius}px`,
                                paddingLeft: `${paddingLeft}px`,
                                paddingTop: `${paddingTop}px`,
                                paddingRight: `${paddingRight}px`,
                                paddingBottom: `${paddingBottom}px`,
                              }}
                              imageAlt={imageAlt}
                              opacity={opacity || 0}
                              currentBlockId={block.blockConfig.id}
                              src={block.content}
                              dimension={imageDimension}
                            />
                          </a>
                        ) : (
                          <ImageComponent
                            style={{
                              borderTop: `solid ${borderColor} ${borderTop}px`,
                              borderBottom: `solid ${borderColor} ${borderBottom}px`,
                              borderLeft: `solid ${borderColor} ${borderLeft}px`,
                              borderRight: `solid ${borderColor} ${borderRight}px`,
                              borderRadius: `${borderRadius}px`,
                              paddingLeft: `${paddingLeft}px`,
                              paddingTop: `${paddingTop}px`,
                              paddingRight: `${paddingRight}px`,
                              paddingBottom: `${paddingBottom}px`,
                            }}
                            imageAlt={imageAlt}
                            opacity={opacity || 0}
                            currentBlockId={block.blockConfig.id}
                            src={block.content}
                            dimension={imageDimension}
                          />
                        )}
                      </PreviewBlock>
                    );
                  } else if (block.type === GridBlockType.TABLE) {
                    return (
                      <PreviewTableBlock key={block.blockConfig.id} block={block} isDocumentLocked={isDocumentLocked} section={section} />
                    );
                  }
                  return null;
                })}
              </div>
              <div className="preview__grid__signatures">
                <div className="preview__signatures__container">
                  <PreviewSignatures
                    documentId={documentId}
                    sectionId={section.id}
                    sectionsSignatures={sectionsSignatures}
                    setSignedSignatureInSection={setSignedSignatureInSection}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default PreviewSections;
