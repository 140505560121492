const ENABLE_TRACKING_LOGGING_BY_DEFAULT = false;

export const TrackingLogger = {
  isEnabled: ENABLE_TRACKING_LOGGING_BY_DEFAULT,
  enable: function () {
    this.isEnabled = true;
  },
  disable: function () {
    this.isEnabled = false;
  },
  log: function (...args: any) {
    if (this.isEnabled) {
      window.console.log(...args);
    }
  },
  table: function (...args: any) {
    if (this.isEnabled) {
      window.console.table(...args);
    }
  },
  error: function (...args: any) {
    if (this.isEnabled) {
      window.console.error(...args);
    }
  },
  warn: function (...args: any) {
    if (this.isEnabled) {
      window.console.warn(...args);
    }
  },
};

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  // in non production environments, you can open the browser console
  // and enable tracking logs by prompting TrackingLogger.enable()
  globalThis.TrackingLogger = TrackingLogger;
}
