import { useTranslation } from 'react-i18next';
import ErrorPage from './ErrorPage';
import NotFound from './images/not-found-icon.png';
import { useLocation } from 'react-router-dom';

const NotFoundErrorPage = () => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);

  return (
    <ErrorPage
      testId="not-found-error-page"
      title={t('errors.not_found.title')}
      content={t('errors.not_found.content')}
      image={<img src={NotFound} width="100%" />}
      action={{
        path: queryParams.get('referrer'),
        text: t('errors.not_found.action'),
      }}
    />
  );
};

export default NotFoundErrorPage;
