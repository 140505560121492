import { useContext, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { SelectChangeEvent } from '@mui/material/Select';
import { GridCellParams } from '@mui/x-data-grid-pro';

import { SidePanelContextColumnsPropsType, SidePanelProviderContext } from '../../SidePanelModelsProvider';
import { RootState } from '../../../../grid/reduxStore/Store';
import { tableColumnTypes } from '../../../../../../muiTheme/MuiDataGrid';
import { TableType, ColumnTypeIdentifier, TableColumnType, RowTypesIdentifier } from '../../../../grid/reduxStore/table.types';
import { SelectionContext } from '../../../../GridDndEditor/SelectedBlockInfoProvider';
import { useTableManipulation } from '../../../../GridDndEditor/Block/Table/useTableManipulation';
import { useBlockContentChangedHandler } from '../../../../hooks/UseBlockContentChangedHandler';
import { useTableColumnManipulation } from './useTableColumnManipulation';
import { TableSettingsTypes } from '../types';

const updateColumns = (
  columns: TableType['columns'],
  columnData: GridCellParams['colDef'],
  newColumnType: ColumnTypeIdentifier,
  isNumericColumn: boolean
): TableColumnType[] => {
  const modifiedColumns = columns.map((column) => {
    const newColumnObject = {
      ...column,
      headerName: newColumnType,
      columnType: newColumnType,
      type: isNumericColumn ? 'number' : 'string',
    } as TableColumnType;
    return column.field !== columnData?.field ? column : newColumnObject;
  });

  return applySubtotalRules(modifiedColumns, newColumnType, isNumericColumn);
};

function applySubtotalRules(
  modifiedColumns: TableColumnType[],
  newColumnType: ColumnTypeIdentifier,
  isNumericColumn: boolean
): TableColumnType[] {
  if (
    newColumnType === ColumnTypeIdentifier.SUBTOTAL &&
    modifiedColumns[modifiedColumns.length - 1].columnType !== ColumnTypeIdentifier.SUBTOTAL
  ) {
    const filteredColumn = modifiedColumns.filter((column) => column.columnType !== ColumnTypeIdentifier.SUBTOTAL);
    const oldColumnForConversion = modifiedColumns.find((column) => column.columnType === ColumnTypeIdentifier.SUBTOTAL);

    if (!oldColumnForConversion) {
      return modifiedColumns;
    }
    const subtotalColumn: TableColumnType = {
      ...oldColumnForConversion,
      headerName: newColumnType,
      columnType: newColumnType,
      field: oldColumnForConversion.field,
      type: isNumericColumn ? 'number' : 'string',
    };

    return [...filteredColumn, subtotalColumn];
  }

  return modifiedColumns;
}

const updateRows = (rows: TableType['rows'], selectedColumn: string, newColumnType: ColumnTypeIdentifier, isNumericColumn: boolean) => {
  return rows.map((row) => {
    if (row.rowType !== RowTypesIdentifier.BODY) return row;
    const isNumeric = !isNaN(Number(row[selectedColumn]));
    return isNumeric === isNumericColumn ? row : { ...row, [selectedColumn]: '', columnType: { [selectedColumn]: true } };
  });
};

export const useTableColumnTypeChange = () => {
  const { toggledTableSettingsPanel } = useContext(SidePanelProviderContext) as SidePanelContextColumnsPropsType;
  const { selectedBlockIdByWrapper, selectedSectionId } = useContext(SelectionContext);
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const { getTableData } = useTableManipulation();
  const { setColumnFocus } = useTableColumnManipulation();

  const [columnType, setColumnType] = useState<string>('');

  const activeTableSettingsPanel = useSelector((state: RootState) => state.blockStyleSettings.activeTableSettingsPanel);

  const getActiveColumnType = useCallback(() => {
    if (toggledTableSettingsPanel) {
      const { colDef } = toggledTableSettingsPanel.tableApi.selectedModel || {};
      const activeColumnKey = colDef?.columnType?.toUpperCase() as string;
      return tableColumnTypes[activeColumnKey] || '';
    }
    return '';
  }, [toggledTableSettingsPanel, tableColumnTypes]);

  useEffect(() => {
    const activeColumnType = getActiveColumnType();
    if (activeColumnType) setColumnType(activeColumnType);
  }, [getActiveColumnType]);

  const handleColumnTypeChange = useCallback(
    async (event: SelectChangeEvent) => {
      const isToggledPanelIncludeIds = !toggledTableSettingsPanel || !selectedBlockIdByWrapper || !selectedSectionId;
      if (activeTableSettingsPanel !== TableSettingsTypes.TABLE_COLUMNS || isToggledPanelIncludeIds) return;

      const { field: selectedColumn } = toggledTableSettingsPanel.tableApi.selectedModel as GridCellParams;
      const newColumnType = event.target.value as ColumnTypeIdentifier;
      const isNumericColumn = [ColumnTypeIdentifier.SUBTOTAL, ColumnTypeIdentifier.PRICE, ColumnTypeIdentifier.MULTIPLIER].includes(
        newColumnType
      );

      const tableData = getTableData(selectedBlockIdByWrapper, selectedSectionId) as TableType;
      const { colDef: columnData } = toggledTableSettingsPanel.tableApi.selectedModel as GridCellParams;

      const updatedColumns = updateColumns(tableData.columns, columnData, newColumnType, isNumericColumn);
      const updatedRows = updateRows(tableData.rows, selectedColumn, newColumnType, isNumericColumn);

      await blockContentChangedHandler(selectedBlockIdByWrapper, selectedSectionId, {
        ...tableData,
        columns: updatedColumns,
        rows: updatedRows,
      });

      setColumnType(newColumnType);
      setColumnFocus(selectedColumn);
    },
    [
      toggledTableSettingsPanel,
      selectedBlockIdByWrapper,
      selectedSectionId,
      activeTableSettingsPanel,
      getTableData,
      blockContentChangedHandler,
    ]
  );

  return {
    columnType,
    handleColumnTypeChange,
  };
};
