import { Theme } from '@mui/material/styles';
import { Components } from '@mui/material/styles/components';

export const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  defaultProps: {
    inputProps: {
      step: 'any',
      min: 0,
      onClick: (e) => e.currentTarget.select(),
    },
  },
  styleOverrides: {
    root: {
      paddingLeft: '6px',
      paddingRight: '6px',
      input: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
};
