import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  SxProps,
} from '@mui/material';
import { Control, Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import { MaskedInputProps } from '../billing/MaskedInputs';
import { SelectOptions } from '../billing/interface';
import { HTMLInputTypeAttribute, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface FormFieldProps<T extends FieldValues> {
  id: string;
  type?: HTMLInputTypeAttribute;
  label?: string;
  placeholder?: string;
  shrinkLabel?: boolean;
  adornments?: React.ReactNode;
  options?: SelectOptions[] | undefined;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  maskInputComponent?: React.ForwardRefExoticComponent<MaskedInputProps & React.RefAttributes<HTMLInputElement>>;
  control: Control<T>;
  rules?: Omit<RegisterOptions<T, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> | undefined;
  helperText?: string;
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  autoComplete?: string | boolean;
  sx?: SxProps;
}

function FormField<T extends FieldValues>(props: FormFieldProps<T>) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const {
    id,
    label,
    type,
    placeholder = '',
    shrinkLabel,
    options,
    maskInputComponent,
    disabled = false,
    control,
    rules,
    helperText = '',
    onFocus,
    autoComplete = '',
    sx,
  } = props;

  let adornments = props.adornments;
  if (!adornments && type === 'password') {
    adornments = (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          sx={{ padding: 0 }}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  }

  return (
    <Controller
      name={id}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <FormControl key={`form-control-${id}`} error={!!error} sx={{ flex: '1 0 0', overflow: 'visible', ...sx }}>
          {options ? (
            <>
              {options.length > 0 && (
                <InputLabel id={`form-field-label-${id}`} error={!!error} shrink={shrinkLabel} sx={{ background: '#fff' }}>
                  {label}
                </InputLabel>
              )}
              <Select
                id={id}
                label={label}
                disabled={disabled}
                error={!!error}
                sx={{
                  ...sx,
                  display: options.length > 0 ? 'inherit' : 'none',
                }}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder || undefined}
                value={value}
                onFocus={onFocus}
              >
                {options.map((option) => (
                  <MenuItem key={`form-field-option-${id}-${option.id}`} value={option.id} disabled={!!option.disabled}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          ) : (
            <>
              {type === 'checkbox' ? (
                <FormControlLabel
                  label={
                    <Typography variant="body2" color="text.primary">
                      {label}
                    </Typography>
                  }
                  disableTypography
                  control={<Checkbox sx={{ alignSelf: 'start' }} checked={value} onChange={onChange} />}
                />
              ) : (
                <TextField
                  id={id}
                  variant={type === 'hidden' ? 'standard' : 'outlined'}
                  type={type === 'password' && showPassword ? 'text' : type}
                  label={type !== 'hidden' ? label : undefined}
                  disabled={disabled}
                  error={!!error}
                  size="medium"
                  InputLabelProps={{
                    shrink: shrinkLabel,
                  }}
                  InputProps={{
                    endAdornment: adornments || undefined,
                    inputComponent: (maskInputComponent as any) || undefined,
                    error: !!error,
                    sx: {
                      visibility: type === 'hidden' ? 'hidden' : 'initial',
                    },
                  }}
                  sx={{
                    flex: '1 0 0',
                    '& < .MuiFormControl-root': {
                      visibility: type === 'hidden' ? 'hidden' : 'initial',
                      position: type === 'hidden' ? 'absolute' : 'initial',
                    },
                  }}
                  autoComplete={typeof autoComplete === 'string' ? autoComplete : label?.toLowerCase() || ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  helperText={error?.message || helperText || undefined}
                  FormHelperTextProps={{
                    error: !!error,
                  }}
                  placeholder={placeholder || undefined}
                  value={value}
                  onFocus={onFocus}
                />
              )}
            </>
          )}
        </FormControl>
      )}
    />
  );
}

export default FormField;
