import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from 'providers/UserProvider';
import { useIsPaused } from '../../../hooks/useIsPaused';
import { useIsUserTrial } from 'hooks/useIsUserTrial';
import { useIsTrialExpired } from 'hooks/useIsTrialExpired';
import useFeatureFlag from 'hooks/useFeatureFlag';

import WorkspaceButton from 'components/workspaceButton';
import LinkTabs from 'components/linkTabs/linkTabs';
import HelpButton from '../../../components/helpbutton/helpbutton';
import { ProfileActions } from '../../../components';

import { FeatureFlags } from 'utils/featureFlags';

import { HeaderBreadcrumb, PlanHeaderProps } from './interface';

const pausedAccountTitle = 'settings.plan.paused_account_title';
const pausedAccountSubtitle = 'settings.plan.paused_account_subtitle';
const trialExpiredTitle = 'settings.plan.trial_expired_title';
const trialExpiredSubtitle = 'settings.plan.trial_expired_subtitle';

const PaidPlanHeader: React.FC<PlanHeaderProps> = ({
  title: overrideTitle,
  firstName,
  paused,
  children,
  breadcrumbs,
  breadcrumbPosition = 'under',
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const featureFlags = useFeatureFlag([FeatureFlags.multiaccounts]);
  const isFetchingFeatureFlags = Object.keys(featureFlags).length === 0;
  const hasWorkspaceAccess = featureFlags[FeatureFlags.multiaccounts];

  const HeaderContainer = styled(Box)(() => ({
    padding: '9px 0 0',
  }));

  const billingMenuItems = breadcrumbs || [
    {
      id: 'summary',
      href: '/settings/summary',
      target: '_self',
      title: t('settings.plan.non_trial.tabs.plan_summary'),
    },
    {
      id: 'plans',
      href: '/settings/plan',
      title: t('settings.plan.non_trial.tabs.view_plans'),
    },
  ];
  let selected = 0;
  if (location.pathname === '/settings/plan') {
    selected = 1;
  }
  const title = overrideTitle ? overrideTitle : !paused ? 'settings.plan.non_trial.title' : pausedAccountTitle;

  const pageHeaderComponent = (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignContent="center"
      alignItems="center"
      sx={{
        margin: '0 9px',
        marginBottom: paused ? 0 : '29px',
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: '24px',
          fontWeight: 600,
          lineHeight: '32px',
          letterSpacing: '.12px',
        }}
      >
        {t(title, { first_name: firstName })}
      </Typography>
      <Box>
        <ProfileActions />
        {!isFetchingFeatureFlags && hasWorkspaceAccess && <WorkspaceButton />}
        <HelpButton />
      </Box>
    </Stack>
  );

  return (
    <>
      {paused && <HeaderContainer>{pageHeaderComponent}</HeaderContainer>}
      <HeaderContainer>
        {breadcrumbPosition === 'above' && billingMenuItems.length > 0 && (
          <LinkTabs selected={-1} items={billingMenuItems} aria-label={t('settings.plan.plans_navigation')} />
        )}
        {!paused && pageHeaderComponent}
        {children}
        <Box sx={{ width: '100%' }}>
          {breadcrumbPosition === 'under' && billingMenuItems.length > 0 && (
            <LinkTabs selected={selected} items={billingMenuItems} aria-label={t('settings.plan.plans_navigation')} />
          )}
        </Box>
      </HeaderContainer>
    </>
  );
};

const UnpaidPlanHeader: React.FC<PlanHeaderProps> = ({ title: overrideTitle, firstName, paused, trialExpired = false }) => {
  const { t } = useTranslation();

  let title = 'settings.plan.title';
  let subtitle = 'settings.plan.subtitle';
  if (paused) {
    title = pausedAccountTitle;
    subtitle = pausedAccountSubtitle;
  } else if (trialExpired) {
    title = trialExpiredTitle;
    subtitle = trialExpiredSubtitle;
  }

  title = overrideTitle || title;

  return (
    <Box gap={2} mt={4}>
      <Typography variant="h4">{t(title, { first_name: firstName })}</Typography>
      <Typography variant="subtitle1">{t(subtitle)}</Typography>
    </Box>
  );
};

interface BillingHeaderProps {
  title?: string;
  breadcrumbs?: HeaderBreadcrumb[];
  children?: React.ReactNode;
  breadcrumbPosition?: 'above' | 'under';
}

const BillingHeader = ({ title = '', breadcrumbs, children, breadcrumbPosition = 'under' }: BillingHeaderProps) => {
  const {
    data: { accountId, firstName = '' },
  } = useCurrentUser();
  const isTrial = useIsUserTrial({ accountId, enabled: !!accountId });
  const paused = useIsPaused();
  const isTrialExpired = useIsTrialExpired({ accountId, enabled: !!accountId });

  return isTrial ? (
    <UnpaidPlanHeader
      title={title}
      breadcrumbs={breadcrumbs}
      firstName={firstName ?? ''}
      paused={paused}
      trialExpired={!!isTrialExpired}
      breadcrumbPosition={breadcrumbPosition}
    >
      {children}
    </UnpaidPlanHeader>
  ) : (
    <PaidPlanHeader
      title={title}
      breadcrumbs={breadcrumbs}
      firstName={firstName ?? ''}
      paused={paused}
      trialExpired={!!isTrialExpired}
      breadcrumbPosition={breadcrumbPosition}
    >
      {children}
    </PaidPlanHeader>
  );
};

export default BillingHeader;
