import SettingsTheme from 'muiTheme/SettingsTheme';
import { Alert, Box, Button, Divider, Skeleton, Stack, ThemeProvider, Typography } from '@mui/material';
import PageLoader from 'components/page-loader/page-loader';
import './styles.less';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useCurrentUser } from 'providers/UserProvider';
import { useSeatsApiData } from 'services/api/seats/hooks/useSeatsApiData';
import { BillingCard, BillingCardActions, BillingCardContent } from '../utils/styled';
import BillingCardHeader from '../utils/BillingCardHeader';
import { Trans, useTranslation } from 'react-i18next';
import {
  SeatsInviteMultipleFormFieldValues,
  transformInvitesFieldValuesToApiPayload,
  useSeatsInviteMultipleForm,
  SubmitMultipleHandler,
} from '../utils/useSeatsInviteForm';
import { useFieldArray, useFormState } from 'react-hook-form';
import { SeatInviteMultipleRow } from 'services/api/seats/interfaces';
import FormField from '../utils/FormField';
import { SelectOptions } from '../billing/interface';
import useSeatInviteMutation from 'services/api/seats/hooks/useSeatInviteMutation';
import { useNavigate } from 'react-router-dom';
import { useUserRolesApiData } from 'services/api/permissions/hooks/useUserRolesApiData';
import { NotificationsContext } from 'components/notifications/NotificationsProvider';
import { isValidEmail } from 'utils/isValidEmail';
import { Link } from 'react-router-dom';

const SeatsInvitePage = () => {
  const { t } = useTranslation();
  const { setNotifications } = useContext(NotificationsContext);
  const navigate = useNavigate();
  const [requestError, setRequestError] = useState<boolean>(false);

  const {
    data: { accountId },
  } = useCurrentUser();

  const { data: seatsData, isLoading: isSeatsLoading } = useSeatsApiData({ accountId: accountId });
  const { data: rolesData, isLoading: isRolesLoading } = useUserRolesApiData({ accountId: accountId });

  const { seatInvite, isLoading: isSendingInvites } = useSeatInviteMutation();

  const initialData = useMemo<SeatsInviteMultipleFormFieldValues>(() => {
    const rows: SeatInviteMultipleRow[] = [];
    if (seatsData?.collaborators?.empty) {
      for (let i = 0; i < seatsData.collaborators.empty; i++) {
        rows.push({
          email: '',
          seatType: 'collaborator',
          roleId: -1,
        });
      }
    }
    if (seatsData?.users?.empty) {
      for (let i = 0; i < seatsData.users.empty; i++) {
        rows.push({
          email: '',
          seatType: 'user',
          roleId: 0,
        });
      }
    }
    return {
      accountId,
      invites: rows,
    };
  }, [accountId, seatsData, isSeatsLoading]);

  const roles = useMemo<SelectOptions[]>(() => {
    if (rolesData?.roles?.length) {
      return rolesData.roles
        .map((role) => ({
          id: role.id?.toString() || '0',
          name: role.name,
          disabled: role.name.toLowerCase() === 'collaborator',
        }))
        .sort((a, b) => {
          if (a.id === null || a.id < b.id) {
            return 1;
          }
          return -1;
        });
    } else {
      return [{ id: '', name: 'Loading...', disabled: true }];
    }
  }, [rolesData]);

  const { handleSubmit, control, getValues, setError } = useSeatsInviteMultipleForm(initialData);
  const { isValidating } = useFormState({ control });

  const { fields } = useFieldArray({
    control,
    name: 'invites',
  });

  const onSubmit: SubmitMultipleHandler = useCallback(
    async (submitData) => {
      const mappedInvites = {};
      let hasErrors = false;
      submitData.invites.map((invite, idx) => {
        if (invite.email === '') {
          return;
        }
        if (mappedInvites[invite.email]) {
          setError(`invites.${idx}.email`, { message: t('settings.seats_invite.duplicates_error') });
          hasErrors = true;
        } else {
          mappedInvites[invite.email] = idx;
        }
      });

      if (seatsData?.occupiedSeats) {
        seatsData.occupiedSeats.map((seat) => {
          // check people already added to the account
          const foundIndex = mappedInvites[seat.email];
          if (foundIndex || foundIndex === 0) {
            // account for first item
            setError(`invites.${foundIndex}.email`, { message: t('settings.seats_invite.already_added_error') });
            hasErrors = true;
          }
        });
      }

      if (hasErrors) {
        return;
      }

      const payload = transformInvitesFieldValuesToApiPayload(submitData);
      const response = await seatInvite({ accountId: accountId, payload: payload });
      if (!response.state) {
        setNotifications([{ content: response.message, severity: 'error' }]);
        setRequestError(true);
      }
      setNotifications({ content: t('settings.seats_invite.invites_sent'), severity: 'success' });
      navigate('/settings/summary');
      return;
    },
    [transformInvitesFieldValuesToApiPayload, seatInvite, accountId, seatsData, setError]
  );

  const filled = useMemo(() => {
    const invites = [...getValues().invites];
    return {
      collaboratorsFilled: invites.filter((f) => f.email !== '' && f.seatType === 'collaborator' && isValidEmail(f.email)).length,
      usersFilled: invites.filter((f) => f.email !== '' && f.seatType === 'user' && f.roleId !== -1 && isValidEmail(f.email)).length,
    };
  }, [isValidating]);

  const isDataLoading = useMemo<boolean>(
    () => isSeatsLoading || isSendingInvites || isRolesLoading,
    [isRolesLoading, isSeatsLoading, isSendingInvites]
  );

  return (
    <PageLoader isLoading={false} isLoaded={true}>
      <ThemeProvider theme={SettingsTheme}>
        <Box>
          <Stack
            data-testid="seats-invite-page-components"
            direction="column"
            sx={{ my: 3 }}
            gap={3}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <BillingCard data-testid="payment-method-summary">
              <BillingCardHeader title={t('settings.seats_invite.title')} />
              <BillingCardContent>
                <Stack direction="row" gap={1}>
                  {seatsData ? (
                    <Typography variant="caption">
                      {t('settings.seats_invite.of_users', {
                        filled: filled.usersFilled,
                        remaining: seatsData.users.empty,
                        seat_type: seatsData.users.empty > 1 ? 'users' : 'user',
                      })}
                    </Typography>
                  ) : (
                    <Skeleton width="100px" height="24px" variant="rounded" />
                  )}
                  <Divider />
                  {seatsData ? (
                    <Typography variant="caption">
                      {t('settings.seats_invite.of_users', {
                        filled: filled.collaboratorsFilled,
                        remaining: seatsData.collaborators.empty,
                        seat_type: seatsData.collaborators.empty > 1 ? 'collaborators' : 'collaborator',
                      })}
                    </Typography>
                  ) : (
                    <Skeleton width="100px" height="24px" variant="rounded" />
                  )}
                </Stack>
                <Stack direction="column" gap={2} width="100%">
                  {isDataLoading &&
                    [0, 1, 2, 3, 4].map((emptyRow) => (
                      <Stack direction="row" justifyContent="space-between" gap={1} key={`seat-invite-skeleton-row-${emptyRow}`}>
                        <Skeleton width="100%" height="32px" sx={{ flex: '1 0 0' }} variant="rounded" />
                        <Skeleton width="100%" height="32px" sx={{ flex: '1 0 0', maxWidth: '340px' }} variant="rounded" />
                      </Stack>
                    ))}
                  {!isSeatsLoading &&
                    fields.map((value, idx) => (
                      <Stack direction="row" justifyContent="space-between" gap={1} key={value.id}>
                        <FormField<SeatsInviteMultipleFormFieldValues>
                          id={`invites[${idx}].email`}
                          control={control}
                          label={t('settings.seats_invite.email')}
                          placeholder={t('settings.seats_invite.email')}
                          shrinkLabel={true}
                          disabled={isDataLoading}
                          rules={{
                            validate: (fieldValue: string) => {
                              if (!fieldValue) {
                                return true;
                              }
                              if (isValidEmail(fieldValue)) {
                                return true;
                              }

                              return t('settings.seats_invite.email_invalid');
                            },
                          }}
                        />
                        <FormField<SeatsInviteMultipleFormFieldValues>
                          id={`invites[${idx}].roleId`}
                          options={value.seatType === 'collaborator' ? [{ id: '-1', name: 'Collaborator' }] : roles}
                          control={control}
                          label={t('settings.seats_invite.role')}
                          placeholder={t('settings.seats_invite.role')}
                          shrinkLabel={true}
                          disabled={value.seatType === 'collaborator' || isDataLoading}
                          sx={{ maxWidth: '340px' }}
                          rules={{
                            validate: (fieldValue: string, formValues) => {
                              if (value.seatType !== 'user') {
                                return true;
                              }
                              if (formValues.invites[idx].email === '') {
                                return true;
                              }
                              return parseInt(fieldValue) > 0 || t('settings.seats_invite.role_invalid');
                            },
                          }}
                        />
                      </Stack>
                    ))}
                  <Box sx={{ justifySelf: 'flex-start' }}>
                    <Button
                      variant="text"
                      onClick={() => {
                        navigate('/settings/summary');
                      }}
                      disabled={isDataLoading}
                    >
                      {t('settings.seats_invite.add_more')}
                    </Button>
                  </Box>
                  {requestError && (
                    <Alert severity="error" variant="filled" square={false}>
                      <Trans
                        i18nKey="settings.seats_invite.request_error"
                        components={{
                          support: <Link to="mailto:support@proposify.com" rel="noreopener" target="_blank" />,
                        }}
                      />
                    </Alert>
                  )}
                </Stack>
              </BillingCardContent>
              <BillingCardActions>
                <Stack direction="row" gap={1} justifyContent="flex-end">
                  <Button
                    variant="text"
                    onClick={() => {
                      navigate('/settings/summary');
                    }}
                    disabled={isDataLoading}
                  >
                    {t('settings.seats_invite.skip')}
                  </Button>
                  <Button variant="contained" type="submit" disabled={isDataLoading}>
                    {t('settings.seats_invite.send')}
                  </Button>
                </Stack>
              </BillingCardActions>
            </BillingCard>
          </Stack>
        </Box>
      </ThemeProvider>
    </PageLoader>
  );
};

export default SeatsInvitePage;
