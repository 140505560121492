import { SeatsPagination } from 'services/api/seats/interfaces';

export const QueryKeys = {
  currentUser: ['/user/me'],
  currentUserPermissions: ['/user/me/role'],
  currentAccount: 'currentAccount',
  getProposalById: (proposalId: number) => ['proposal', proposalId],
  getPersonById: (personId: number) => ['person', personId],
  getPersons: 'persons',
  getCompanyById: (companyId: number | undefined | null) => ['company', companyId],
  getCompanies: ['companies'],
  getProspects: (offset: number, limit: number, search: string) => ['prospects', offset, limit, search],
  getDocumentList: 'listDocuments',
  accountUsers: 'accountUsers',
  variables: 'variables',
  getSignaturesByDocumentId: (documentId: string) => ['signatures', documentId],
  ssoConfig: 'ssoConfig',
  ssoSchema: 'ssoSchema',
  ssoRefSchema: (reference: string | undefined) => ['ssoRefSchema', reference],
  ssoSettings: 'ssoSetting',
  getProposalExpiration: (porposalId: number) => ['proposal', porposalId, 'expiration'],
  userIntegrations: ['userIntegrations'],
  userOauthData: ['userOauthData'],
  getProposalContent: (proposalId: string | number) => ['proposal', proposalId, 'content'],
  getAccountPlan: (accountId: number) => ['account', 'plan', accountId],
  getBillingInfo: (accountId: number) => ['account', accountId],
  getBillingHistory: (accountId: number, cursor: string, numPerPage: number) => [
    'account',
    accountId,
    'cursor',
    cursor,
    'numPerPage',
    numPerPage,
  ],
  getProposalShareTemplate: (proposaId: number, templateId: number) => ['proposal', proposaId, 'template', templateId],
  fonts: 'fonts',
  featureFlag: {
    getSpecificOptionalFeature: (flag: string) => ['specificOptionalFeature', flag],
    getOptionalFeatures: 'getOptionalFeatures',
  },
  getRoles: 'roles',
  getDocumentById: (documentId: string, isPreview = false) => ['documents', documentId, 'isPreview', isPreview],
  getContentStyleById: (contentId: string) => ['getContentStyleById', contentId],
  getGridsByDocumentId: (documentId: string) => ['documents/grids', documentId],
  salesforceCrm: 'salesforceCrm',
  salesforceObjects: 'salesforceObjects',
  salesforceCrmPrefix: (prefix: string) => ['salesforceCrmPrefix', prefix],
  getTemplateFolders: 'templateFolders',
  getLinkedAccounts: 'linkedAccounts',
  getWorkspaceAccounts: 'workspaceAccounts',
  accountAccess: 'accountAccess',
  documentImageLibraryImages: (documentId: string) => ['/documents/document-image-library', documentId],
  uploadDocumentLibraryImage: (documentId: string) => ['/documents/document-image-library', documentId],
  getAPIClients: 'getAPIClients',
  getAssetByAssetId: (assetId: string) => ['assets', assetId],
  getLocationData: 'getLocationData',
  getBillingOptions: (accountId: number, planName: string, promoCode: string) => [
    'bookingOptions',
    accountId,
    planName,
    { promoCode: promoCode },
  ],
  validateCoupon: (accountId: number, couponCode: string) => ['validateCoupon', accountId, couponCode],
  getPrismaticJWT: 'getPrismaticJWT',
  getPrismaticCredentials: 'getPrismaticCredentials',
  getSubscription: (accountId: number) => ['getSubscription', accountId],
  getPaymentAccount: (accountId: number) => ['getPaymentAccount', accountId],
  updatePaymentAccount: (accountId: number) => ['updatePaymentAccount', accountId],
  getInvitation: (token: string) => ['getInvitation', token],
  getSeats: (accountId: number, pagination?: SeatsPagination) => [
    'getSeats',
    accountId,
    pagination?.rowsPerPage,
    pagination?.showEmpty,
    pagination?.cursor,
  ],
  unassignSeat: (accountId: number) => ['unassignSeat', accountId],
  removeSeat: (accountId: number) => ['removeSeat', accountId],
  getUserRoles: (accountId: number) => ['getUserRoles', accountId],
  getDocumentCollaborators: (documentId: string) => ['getDocumentCollaborators', documentId],
  getFlexDataTopLevelObjectTypes: 'getFlexDataTopLevelObjectTypes',
  getFlexDataLinks: (documentId: string) => ['getFlexDataLinks', documentId],
};
