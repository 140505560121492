import { useMediaQuery } from 'hooks/useMediaQuery';
import DesktopMenu from './desktopMenu';
import MobileMenu from './mobileMenu';

export interface SidebarProps {
  mode?: 'fixed' | 'collapsible';
}

export default function Sidebar({ mode }: SidebarProps) {
  const { isDesktop, isSmallerThanDesktop } = useMediaQuery();
  const isDesktopView = mode === 'fixed' && isDesktop;
  const isMobileView = mode === 'collapsible' && isSmallerThanDesktop;

  if (isDesktopView) {
    return <DesktopMenu />;
  } else if (isMobileView) {
    return <MobileMenu />;
  } else {
    return <></>;
  }
}
