import { useMutation } from 'react-query';
import { ApiAccountRepository } from 'services/api/account/ApiAccountRepository';
import { RegisterAccountApiResponse, RegisterAccountEntity } from 'services/api/account/interfaces';

export default function useRegisterAccountMutation() {
  const reactQuery = useMutation({
    mutationFn: RegisterAccount,
  });

  return { registerAccount: reactQuery.mutateAsync, registerAccountPromise: reactQuery.mutate, isLoading: reactQuery.isLoading };
}

const RegisterAccount = async (data: RegisterAccountEntity): Promise<RegisterAccountApiResponse> => {
  const accountApi = new ApiAccountRepository();
  const response = await accountApi.registerAccount(data);

  return {
    state: response.state,
    message: response.message,
  };
};
