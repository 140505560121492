import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Close from '@mui/icons-material/Close';
import SeatsInviteForm from './SeatsInviteForm';

interface SeatsInviteModalProps {
  isOpen: boolean;
  setIsOpen: (boolean) => void;
  onSuccess: () => void;
}

const SeatsInviteModal = ({ isOpen, setIsOpen, onSuccess }: SeatsInviteModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} data-testid="seat-invite-modal">
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{t('settings.summary.seat_invite.title')}</Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsOpen(false);
            }}
            sx={(theme) => ({
              color: theme.palette.grey[500],
              padding: '5px',
            })}
            data-testid="seat-invite-modal-close"
          >
            <Close sx={{ height: '20px', width: '20px' }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <SeatsInviteForm
          onSuccess={onSuccess}
          onCancel={() => {
            setIsOpen(false);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SeatsInviteModal;
