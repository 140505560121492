import { Dialog, DialogContent, DialogTitle, IconButton, Stack, ThemeProvider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Close from '@mui/icons-material/Close';
import DocumentInviteForm from './DocumentInviteForm';
import SettingsTheme from 'muiTheme/SettingsTheme';

interface DocumentInviteModalProps {
  isOpen: boolean;
  setIsOpen: (boolean) => void;
  documentId: string;
  documentName: string;
}

const DocumentInviteModal = ({ isOpen, setIsOpen, documentId, documentName }: DocumentInviteModalProps) => {
  const { t } = useTranslation();

  if (!isOpen) {
    return null;
  }

  return (
    <ThemeProvider theme={SettingsTheme}>
      <Dialog open={isOpen} data-testid="document-invite-modal">
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">{t('document_invite.title', { documentName: documentName })}</Typography>
            <IconButton
              aria-label="close"
              onClick={() => {
                setIsOpen(false);
              }}
              sx={(theme) => ({
                color: theme.palette.grey[500],
                padding: '5px',
              })}
              data-testid="document-invite-modal-close"
            >
              <Close sx={{ height: '20px', width: '20px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DocumentInviteForm documentId={documentId} />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default DocumentInviteModal;
