import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSeatsAddForm, SubmitHandler, transformSeatsFieldValuesToApiPayload, SeatsAddFormFieldValues } from '../utils/useSeatsAddForm';
import { useCallback, useContext } from 'react';
import { PlanSummaryContext } from './PlanSummaryProvider';
import { formatAsPrice } from '../utils/utils';
import Incrementer from '../utils/Incrementer';
import { AddSeatsEntity } from 'services/api/seats/interfaces';

interface SeatsFormProps {
  onSuccess: (payload: AddSeatsEntity) => void;
  data: AddSeatsEntity;
}

const SeatsAddForm = ({ onSuccess, data }: SeatsFormProps) => {
  const { t } = useTranslation();
  const { planData, accountId } = useContext(PlanSummaryContext);
  const { handleSubmit, control } = useSeatsAddForm(data);

  const onSubmit: SubmitHandler = useCallback(
    async (submitData) => {
      const payload = transformSeatsFieldValuesToApiPayload({ ...submitData, accountId });
      onSuccess(payload);
    },
    [transformSeatsFieldValuesToApiPayload, onSuccess]
  );

  const pricePerSeat = planData?.pricePerSeatPerInterval || -1;
  const pricePerCollaborator = 8;
  const userInterval = planData?.billingInterval ? planData.billingInterval.valueOf().toLowerCase() : '-';

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} data-testid="seat-add-form">
      <Stack direction="column" sx={{ pt: '5px', gap: 3 }}>
        <Stack direction="row" gap={2} justifyContent="space-between" alignContent="center">
          <Stack direction="column">
            <Typography variant="body1" fontWeight="700">
              {t('settings.summary.seat_add.user')}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t('settings.summary.seat_add.per_seat_interval', {
                price: pricePerSeat == -1 ? '--' : formatAsPrice(pricePerSeat, true),
                interval: userInterval,
              })}
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography variant="body1" sx={{ display: 'flex', alignSelf: 'center' }}>
              {data.userSeats > 0 && pricePerSeat > -1 ? formatAsPrice(data.userSeats * pricePerSeat) : '--'}
            </Typography>
            <Incrementer<SeatsAddFormFieldValues>
              id="userSeats"
              control={control}
              onUpdate={(value) => onSuccess({ ...data, userSeats: value, accountId })}
              disabled={pricePerSeat < 0}
            />
          </Stack>
        </Stack>
        <Stack direction="row" gap={2} justifyContent="space-between" alignContent="center">
          <Stack direction="column">
            <Typography variant="body1" fontWeight="700">
              {t('settings.summary.seat_add.collaborator')}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t('settings.summary.seat_add.per_seat', {
                price: formatAsPrice(pricePerCollaborator, true),
              })}
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography variant="body1" sx={{ display: 'flex', alignSelf: 'center' }}>
              {data.collaboratorSeats > 0 && pricePerCollaborator > -1
                ? formatAsPrice(data.collaboratorSeats * pricePerCollaborator)
                : '--'}
            </Typography>
            <Incrementer<SeatsAddFormFieldValues>
              id="collaboratorSeats"
              control={control}
              onUpdate={(value) => onSuccess({ ...data, collaboratorSeats: value, accountId })}
              disabled={pricePerCollaborator < 0}
            />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default SeatsAddForm;
