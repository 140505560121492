import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { SeatsEntity, SeatsPagination } from 'services/api/seats/interfaces';
import { ApiSeatsRepository } from 'services/api/seats/ApiSeatsRepository';

type UseSeatsApiDataParams = {
  accountId: number;
  pagination?: SeatsPagination;
};

type UseSeatsApiDataResult = {
  data?: SeatsEntity | null;
  isLoading: boolean;
  isError: boolean;
  isFetched: boolean;
  isRefetching: boolean;
  refetch;
};

type UseSeatsApiData<P> = (params: P) => UseSeatsApiDataResult;

export const useSeatsApiData: UseSeatsApiData<UseSeatsApiDataParams> = ({ accountId, pagination }) => {
  const apiSeatsRepository = new ApiSeatsRepository();
  const { data, isLoading, isError, isFetched, refetch, isRefetching } = useQuery({
    queryKey: QueryKeys.getSeats(accountId, pagination),
    queryFn: () => apiSeatsRepository.getSeats(accountId, pagination),
  });

  return {
    data,
    isLoading,
    isError,
    isFetched,
    isRefetching,
    refetch,
  };
};
