import { useMutation } from 'react-query';
import { ApiSeatsRepository } from 'services/api/seats/ApiSeatsRepository';
import { BasicApiResponse, AddSeatsEntity } from 'services/api/seats/interfaces';

type AddSeatsParam = {
  payload: AddSeatsEntity;
};

export default function useAddSeatsMutation() {
  const reactQuery = useMutation({
    mutationFn: addSeats,
  });

  return { addSeats: reactQuery.mutateAsync, addSeatsPromise: reactQuery.mutate, isLoading: reactQuery.isLoading };
}

const addSeats = async (data: AddSeatsParam): Promise<BasicApiResponse> => {
  const seatsApi = new ApiSeatsRepository();
  const response = await seatsApi.addSeats(data.payload);

  return {
    state: response.state,
    message: response.message,
  };
};
