import { useLocalStorage } from 'hooks/useLocalStorage';

interface Viewer {
  id: string;
  email: string;
}

export const useViewerInfo = () => {
  const [viewer, setViewer] = useLocalStorage<Viewer>('viewer', { id: '', email: '' });

  const setViewerEmail = (email: string) => {
    setViewer((identifiedViewer: Viewer) => ({ ...identifiedViewer, email: email }));
  };

  const setViewerDetails = (id: string, email: string) => {
    setViewer({ id, email: email });
  };

  const clearViewerEmail = () => {
    setViewer((identifiedViewer: Viewer) => ({ ...identifiedViewer, email: '' }));
  };

  return { viewer, setViewerEmail, clearViewerEmail, setViewerDetails };
};
