import { TFunction, useTranslation } from 'react-i18next';

function getTimeDifference({ date, t }: { date: string; t: TFunction }): string {
  const dateObj = new Date(date);
  const today = Date.now();

  const diffInSeconds = Math.round((today - dateObj.getTime()) / 1000);
  if (diffInSeconds < 60) return t('pipeline.utils.seconds_ago', { seconds: diffInSeconds });
  const diffInMinutes = Math.round(diffInSeconds / 60);
  if (diffInMinutes < 60) return t('pipeline.utils.minutes_ago', { minutes: diffInMinutes });
  const diffInHours = Math.round(diffInMinutes / 60);
  if (diffInHours < 24) return t('pipeline.utils.hours_ago', { hours: diffInHours });
  const diffInDays = Math.round(diffInHours / 24);
  if (diffInDays < 30) return t('pipeline.utils.days_ago', { days: diffInDays });
  const diffInMonths = Math.round(diffInDays / 30);
  if (diffInMonths < 12) return t('pipeline.utils.months_ago', { months: diffInMonths });
  const diffInYears = Math.round(diffInMonths / 12);
  return t('pipeline.utils.years_ago', { years: diffInYears });
}

export function useFormatRelativeDateTime() {
  const { t } = useTranslation();

  return {
    formatRelativeDateTime: (date: string) => {
      return getTimeDifference({ date, t });
    },
  };
}
