import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ColumnTypeIdentifier, TableRowType } from '../../../grid/reduxStore/table.types';
import { getIsCellEditingAllowed, TableNumericCell } from '../Table/tableUtils/customCellHelpers';
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import UpDownArrowIcons from '../Table/components/UpDownArrowIcons';
interface PreviewEditableCellProps {
  params: GridRenderCellParams;
  columnType: ColumnTypeIdentifier;
  pricingDecimalPlaces?: number;
  updateRows: (updatedRow: TableRowType) => void;
  isDocumentLocked: boolean;
}

const PreviewEditableCellWrapper = ({
  params,
  columnType,
  pricingDecimalPlaces,
  updateRows,
  isDocumentLocked,
}: PreviewEditableCellProps): JSX.Element => {
  const { row, colDef, value } = params;
  const label = { inputProps: { 'aria-label': 'Optional row' } };
  const isCheckboxAvailable = (row?.isOptionalToggleAvailable && colDef['columnType'] === ColumnTypeIdentifier.OPTIONAL) ?? false;
  const isCellEditingAllowed = getIsCellEditingAllowed({ row, colDef });

  if (isCellEditingAllowed) {
    return (
      <Box component="span" display="flex" alignItems="center" gap="3px">
        {String(value)} <UpDownArrowIcons />
      </Box>
    );
  }
  if (isCheckboxAvailable) {
    return (
      <Checkbox
        {...label}
        className={!isDocumentLocked ? 'py-row-optional' : ''}
        data-testid="row-optional-preview-checkbox"
        disabled={isDocumentLocked}
        checked={row?.isRowOptional ?? false}
        onChange={() => updateRows({ ...row, isRowOptional: !row?.isRowOptional })}
      />
    );
  }

  return (
    <TableNumericCell
      isDocumentLocked={isDocumentLocked}
      params={params}
      columnType={columnType}
      numberOfDecimalPlaces={pricingDecimalPlaces}
    />
  );
};

export default PreviewEditableCellWrapper;
