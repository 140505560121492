interface Logger {
  log: (...args: any[]) => void;
  error: (...args: any[]) => void;
  warn: (...args: any[]) => void;
}

export const createIndexedDBHandler = <T, K extends keyof T & string>({
  logger,
  databaseName,
  databaseVersion,
  tableName,
  primaryKey,
}: {
  logger: Logger;
  databaseName: string;
  databaseVersion: number;
  tableName: string;
  primaryKey: K | K[];
}) => {
  function openDatabase(callback: (db: IDBDatabase) => void) {
    const request = indexedDB.open(databaseName, databaseVersion);

    request.onsuccess = function ({ target }) {
      const db = (target as IDBOpenDBRequest).result;
      callback(db);
    };

    request.onerror = function (event) {
      logger.error(`indexeddb openDatabase: Failed to open database ${databaseName}`, event);
    };

    request.onupgradeneeded = function (event) {
      const db = (event.target as IDBOpenDBRequest).result;

      const objectStore = db.createObjectStore(tableName, { keyPath: primaryKey });

      objectStore.transaction.oncomplete = () => {
        logger.log(`indexeddb openDatabase: object store "${tableName}" created successfully`);
      };
    };
  }

  function write(data: T) {
    openDatabase((db) => {
      const tx = db.transaction(tableName, 'readwrite').objectStore(tableName).put(data);

      tx.onsuccess = () => {
        logger.log(`indexeddb write: data written in indexedDB successfully for: ${JSON.stringify(data, null, 2)}`);
      };

      tx.onerror = (event) => {
        logger.error('indexeddb write: error writing data', event);
      };
    });
  }

  function read(key: string, callback: (data: T) => void) {
    openDatabase((db) => {
      const tx = db.transaction(tableName, 'readonly');
      const store = tx.objectStore(tableName);
      const request = store.get(key);

      request.onsuccess = (event) => {
        const result = (event.target as IDBRequest).result;
        if (result) {
          logger.log(`indexeddb read: retrieved from indexedDB: ${JSON.stringify(result, null, 2)}`);
          callback(result);
        } else {
          logger.warn(`indexeddb read: no data found in indexedDB for table "${tableName}" with ${primaryKey}="${key}"`);
        }
      };

      request.onerror = (event) => {
        logger.error(`indexeddb read: error reading data in ${tableName} with ${primaryKey}=${key}`, event);
      };
    });
  }

  return {
    write: (data: T) => write(data),
    read: (key: string, callback: (data: T) => void) => read(key, callback),
  };
};
