import { createRoot } from 'react-dom/client';

import { I18nProvider } from 'providers/I18nProvider';
import { QueryProvider } from 'providers/QueryProvider';
import { DatatogTracker } from 'vendor/datadog';
import App from './App';
import { GlobalErrorBoundary } from './GlobalErrorBoundary';
import './index.css';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <DatatogTracker>
    <GlobalErrorBoundary fallback="Oh no, Something went wrong. Please refresh the page." scope="app">
      <I18nProvider>
        <QueryProvider>
          <App />
        </QueryProvider>
      </I18nProvider>
    </GlobalErrorBoundary>
  </DatatogTracker>
);
