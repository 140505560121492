import { Endpoints } from '../../../utils/endpoints';
import { apiClient } from 'services/api';
import { SeatsRepository } from './SeatsRepository';
import {
  SeatsEntity,
  SeatsApiResponse,
  SeatInviteEntity,
  BasicApiResponse,
  SeatsPagination,
  AddSeatsEntity,
  PreviewBasicApiResponse,
  SeatInviteMultipleEntity,
  RemoveSeatEntity,
  UnassignSeatEntity,
  ResendInviteEntity,
  DocumentInviteEntity,
  DocumentCollaboratorsApiResponse,
  DocumentRemoveAccessEntity,
  DocumentCollaboratorEntity,
} from 'services/api/seats/interfaces';
import { AxiosError } from 'axios';

export class ApiSeatsRepository implements SeatsRepository {
  public async unassignSeat(payload: UnassignSeatEntity): Promise<BasicApiResponse> {
    try {
      return await apiClient.patch<BasicApiResponse>(Endpoints.unassignSeat(payload.accountId), payload);
    } catch (error) {
      const err = error as AxiosError;
      const data = err.response?.data ? (err.response.data as { state: boolean; message: string }) : { state: false, message: '' };
      return { state: data.state || false, message: data.message };
    }
  }
  public async removeSeat(payload: RemoveSeatEntity): Promise<BasicApiResponse> {
    try {
      return await apiClient.patch<BasicApiResponse>(Endpoints.removeSeat(payload.accountId), payload);
    } catch (error) {
      const err = error as AxiosError;
      const data = err.response?.data ? (err.response.data as { state: boolean; message: string }) : { state: false, message: '' };
      return { state: data.state || false, message: data.message };
    }
  }
  public async resendInvite(payload: ResendInviteEntity): Promise<BasicApiResponse> {
    try {
      return await apiClient.patch<BasicApiResponse>(Endpoints.resendInvite(payload.accountId), payload);
    } catch (error) {
      const err = error as AxiosError;
      const data = err.response?.data ? (err.response.data as { state: boolean; message: string }) : { state: false, message: '' };
      return { state: data.state || false, message: data.message };
    }
  }
  public async addSeats(payload: AddSeatsEntity): Promise<BasicApiResponse> {
    try {
      return await apiClient.patch<BasicApiResponse>(Endpoints.addSeats(payload.accountId), payload);
    } catch (error) {
      const err = error as AxiosError;
      const data = err.response?.data ? (err.response.data as { state: boolean; message: string }) : { state: false, message: '' };
      return { state: data.state || false, message: data.message };
    }
  }

  public async previewAddSeats(payload: AddSeatsEntity): Promise<PreviewBasicApiResponse> {
    try {
      return await apiClient.post<BasicApiResponse>(Endpoints.previewAddSeats(payload.accountId), payload);
    } catch (error) {
      const err = error as AxiosError;
      const data = err.response?.data ? (err.response.data as { state: boolean; message: string }) : { state: false, message: '' };
      return { state: data.state || false, message: data.message };
    }
  }

  public async getSeats(accountId: number, pagination?: SeatsPagination): Promise<SeatsEntity | null> {
    try {
      const response = await apiClient.get<SeatsApiResponse>(Endpoints.getSeats(accountId), { params: pagination });
      return response.seats;
    } catch (error) {
      return null;
    }
  }

  public async seatInvite(payload: SeatInviteEntity | SeatInviteMultipleEntity): Promise<BasicApiResponse> {
    try {
      return await apiClient.post<BasicApiResponse>(Endpoints.seatInvite(payload.accountId), payload);
    } catch (error) {
      const err = error as AxiosError;
      const data = err.response?.data ? (err.response.data as { state: boolean; message: string }) : { state: false, message: '' };
      return { state: data.state || false, message: data.message };
    }
  }

  public async getDocumentCollaborators(documentId: string): Promise<DocumentCollaboratorEntity[] | null> {
    try {
      const response = await apiClient.get<DocumentCollaboratorsApiResponse>(Endpoints.getDocumentCollaborators(documentId));
      return response.collaborators;
    } catch (error) {
      return null;
    }
  }

  public async documentInvite(payload: DocumentInviteEntity): Promise<BasicApiResponse> {
    try {
      return await apiClient.patch<BasicApiResponse>(Endpoints.documentInvite(payload.documentId), payload);
    } catch (error) {
      const err = error as AxiosError;
      const data = err.response?.data ? (err.response.data as { state: boolean; message: string }) : { state: false, message: '' };
      return { state: data.state || false, message: data.message };
    }
  }

  public async documentRemoveAccess(payload: DocumentRemoveAccessEntity): Promise<BasicApiResponse> {
    try {
      return await apiClient.deleteWithData<BasicApiResponse>(Endpoints.documentRemoveAccess(payload.documentId), payload);
    } catch (error) {
      const err = error as AxiosError;
      const data = err.response?.data ? (err.response.data as { state: boolean; message: string }) : { state: false, message: '' };
      return { state: data.state || false, message: data.message };
    }
  }
}
