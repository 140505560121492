import { useState } from 'react';
import { useQuery } from 'react-query';
import { FlexDataLinkRepository } from 'services/flex-data/repositories/FlexDataLinkRepository';
import { QueryKeys } from 'utils/queryKeys';

interface FlexDataLink {
  dataSourceType: string;
  objectType: string;
  tenantId: string;
  qualifier: string;
  objectId: string;
  objectLabel: string;
}

interface FlexDataLinkResponse {
  data: FlexDataLink[];
  metadata: {
    count: number;
  };
}

export const useFlexDataLink = (documentId: string) => {
  const [flexDataLinks, setFlexDataLinks] = useState<FlexDataLink[]>([]);
  const { isLoading, isError } = useQuery({
    queryKey: QueryKeys.getFlexDataLinks(documentId),
    queryFn: () => FlexDataLinkRepository.getFlexDataLinks(documentId),
    onSuccess: (data: FlexDataLinkResponse) => {
      setFlexDataLinks(data.data);
    },
  });

  return { isFlexDataLinkLoading: isLoading, flexDataLinks, isFlexDataLinkError: isError };
};
