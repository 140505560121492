import { Skeleton, Tooltip, Typography, TypographyProps } from '@mui/material';

import { useEffect, useRef, useState } from 'react';
import { useInsightsContext } from '../InsightsProvider';

interface DocumentTitleProps {
  variant: TypographyProps['variant'];
}

export function DocumentTitle({ variant }: DocumentTitleProps) {
  const { getCurrentDocument, hasErrors } = useInsightsContext();
  const titleRef = useRef<HTMLDivElement>(null);
  const [isTextEllipsed, setIsTextEllipsed] = useState(false);

  const title = getCurrentDocument()?.title;

  useEffect(() => {
    if (titleRef.current) {
      setIsTextEllipsed(titleRef.current.clientWidth < titleRef.current.scrollWidth);
    }
  }, [title]);

  if (hasErrors) {
    return null;
  }

  if (!title) {
    return <Skeleton data-testid="document-title-skeleton" variant="text" width="50%" height={32} />;
  }

  return (
    <Tooltip title={isTextEllipsed ? title : undefined}>
      <Typography ref={titleRef} data-testid="insights-header-title" variant={variant} fontWeight={600} noWrap>
        {title}
      </Typography>
    </Tooltip>
  );
}
