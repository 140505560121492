import { useForm, SubmitHandler as SubmitHandlerT } from 'react-hook-form';
import { AcceptInvitationEntity } from 'services/api/account/interfaces';

export type AcceptInvitationFormFieldValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  receiveEmails: boolean;
  token: string;
};

export type SubmitHandler = SubmitHandlerT<AcceptInvitationFormFieldValues>;

// everything is currently the same, but could change based on needs later on
export const transformAcceptInvitationFieldValuesToApiPayload = ({
  email,
  firstName,
  lastName,
  password,
  receiveEmails,
  token,
}: AcceptInvitationFormFieldValues): AcceptInvitationEntity => {
  return {
    email,
    firstName,
    lastName,
    password,
    receiveEmails,
    token,
  };
};

export function useAcceptInvitationForm(loadedValues?: AcceptInvitationFormFieldValues) {
  return useForm<AcceptInvitationFormFieldValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      password: '',
      email: '',
      receiveEmails: false,
      token: '',
    },
    mode: 'onTouched',
    values: loadedValues || undefined,
  });
}
