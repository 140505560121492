import { WarningAmberOutlined } from '@mui/icons-material';
import { Skeleton, Stack, Tooltip, Typography } from '@mui/material';

export interface SummaryBlockParams {
  title: string;
  value: string;
  extraData?: string | null;
  isLoading?: boolean;
  isLoadingExtra?: boolean;
  tooltip?: string | null;
  textAlign?: 'flex-start' | 'flex-end' | 'center';
}

export const SummaryBlock = ({
  title,
  value,
  extraData,
  isLoading = false,
  isLoadingExtra = false,
  tooltip = null,
  textAlign = 'flex-start',
}: SummaryBlockParams) => (
  <Stack direction="column" gap={0} flexGrow={{ xs: 1, sm: 0 }} width={{ xs: '40%', sm: 'auto' }}>
    <Typography variant="body2" color="text.secondary" mb={2}>
      {title}
    </Typography>
    {isLoading ? (
      <>
        <Skeleton variant="rounded" width="100px" height="32px" sx={{ mb: 2 }} />
        {isLoadingExtra && <Skeleton variant="rounded" width="100px" height="14px" />}
      </>
    ) : (
      <>
        <Stack direction="row" justifyContent={textAlign} alignItems="center" gap={1}>
          <Typography variant="h6" color="text.primary" fontWeight="600">
            {value}
          </Typography>
          {tooltip && (
            <Tooltip title={tooltip}>
              <WarningAmberOutlined color="warning" />
            </Tooltip>
          )}
        </Stack>

        {extraData && (
          <Typography variant="body1" color="text.primary">
            {extraData}
          </Typography>
        )}
      </>
    )}
  </Stack>
);
