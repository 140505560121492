import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useBillingContactForm,
  SubmitHandler,
  transformBillingContactFieldValuesToApiPayload,
  BillingContactFormFieldValues,
} from '../utils/useBillingContactForm';
import FormField from '../utils/FormField';
import { useCallback, useContext, useEffect } from 'react';
import { PlanSummaryContext } from './PlanSummaryProvider';
import { parseUnformattedApiErrors } from '../utils/utils';
import useUpdatePaymentAccountMutation from 'hooks/useUpdatePaymentAccountMutation';
import { PaymentAccountEntity } from 'services/api/account/interfaces';
import { ClickAwayListener } from '@mui/material';
import { MixedNotificationType } from 'components/notifications/NotificationsProvider';

interface BillingContactFormProps {
  onCancel: () => void;
  onSuccess: () => void;
  onFailure: () => void;
}

const getDefaultValues = (contactData: PaymentAccountEntity | undefined) => {
  return {
    email: contactData?.email || '',
  };
};

const BillingContactForm = ({ onSuccess, onFailure, onCancel }: BillingContactFormProps) => {
  const { t } = useTranslation();

  const { accountId, contactData, isContactFetched, setNotifications, refetchContact, isContactRefetching } =
    useContext(PlanSummaryContext);
  const { handleSubmit, control, reset, setError, formState, getValues } = useBillingContactForm(getDefaultValues(contactData));
  const { updatePaymentAccount, isLoading: isUpdating } = useUpdatePaymentAccountMutation();

  const onSubmit: SubmitHandler = useCallback(
    async (submitData) => {
      const payload = transformBillingContactFieldValuesToApiPayload(submitData);
      const response = await updatePaymentAccount({ accountId: accountId, payload: payload });
      if (response.state) {
        refetchContact();
        onSuccess();
        return;
      }
      const parsedErrors = parseUnformattedApiErrors(response.message);
      const newApiErrors: MixedNotificationType[] = [];
      parsedErrors.map((err) => {
        if (formState.defaultValues && err.field) {
          const key = err.field as keyof BillingContactFormFieldValues;
          if (key in formState.defaultValues) {
            setError(key, { message: t(err.message) });
            return;
          }
        }
        newApiErrors.push({ content: t(err.message), severity: 'error' });
      }) || [];
      setNotifications(newApiErrors);
      onFailure();
    },
    [transformBillingContactFieldValuesToApiPayload, updatePaymentAccount, onSuccess, onFailure]
  );

  useEffect(() => {
    if (reset) {
      reset(getDefaultValues(contactData));
    }
  }, [contactData, reset]);

  const getIsLoading = useCallback(() => {
    return !isContactFetched || isUpdating || !contactData || isContactRefetching;
  }, [isContactFetched, isUpdating, contactData, isContactRefetching]);

  return !isContactFetched ? (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => {
        !formState.isDirty && onCancel();
      }}
    >
      <Skeleton variant="rounded" width="100%" height="40px" />
    </ClickAwayListener>
  ) : (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => {
        Object.keys(formState.touchedFields).length === 0 && onCancel();
      }}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} data-testid="billing-contact-form">
        <Stack direction="column" sx={{ pt: '5px', gap: 3 }}>
          <Typography variant="body2" color="text.primary">
            {t('settings.summary.billing_contact_form.disclaimer')}
          </Typography>
          <Stack direction="row" gap={2} justifyContent="space-between" alignContent="center">
            <FormField<BillingContactFormFieldValues>
              id="email"
              label={t('settings.summary.billing_contact_form.email')}
              placeholder={t('settings.summary.billing_contact_form.email')}
              control={control}
              shrinkLabel={true}
              rules={{
                required: t('required_field'),
                pattern: {
                  value: /[a-z0-9._%+!$&*=^|~#%'`?{}/-]+@([a-z0-9-]+\.){1,}([a-z]{2,16})/g,
                  message: t('settings.summary.billing_contact_form.email_invalid'),
                },
              }}
              disabled={getIsLoading()}
            />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignContent="center" pt={3} gap={1}>
          <Button variant="text" size="medium" onClick={onCancel} disabled={isUpdating} data-testid="billing-contact-cancel">
            {t('settings.summary.billing_contact_form.cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="medium"
            onClick={() => (contactData?.email !== getValues('email') ? handleSubmit(onSubmit) : onCancel())}
            disabled={getIsLoading()}
            data-testid="billing-contact-form-submit"
          >
            {isUpdating ? t('saving') : t('save_changes')}
          </Button>
        </Stack>
      </Box>
    </ClickAwayListener>
  );
};

export default BillingContactForm;
