import { useTranslation } from 'react-i18next';
import ErrorPage from './ErrorPage';
import NoAccess from './images/no-access-icon.png';

const NoAccessAccountErrorPage = () => {
  const { t } = useTranslation();

  return (
    <ErrorPage
      testId={'no-access-account-error-page'}
      title={t('errors.no_access_account.title')}
      content={t('errors.no_access_account.content')}
      image={<img src={NoAccess} width="100%" />}
      action={{
        external: true,
        path: 'https://www.proposify.com',
        text: t('errors.no_access_account.action'),
      }}
    />
  );
};

export default NoAccessAccountErrorPage;
