import { SelectOptions } from 'pages/settings/billing/interface';
import { useForm, SubmitHandler as SubmitHandlerT } from 'react-hook-form';
import { DocumentInviteEntity } from 'services/api/seats/interfaces';

export type DocumentInviteFormFieldValues = {
  email: SelectOptions | string | null;
  accountId: number;
  documentId: string;
};

export type SubmitHandler = SubmitHandlerT<DocumentInviteFormFieldValues>;

export const transformDocumentInviteFieldValuesToApiPayload = ({
  email,
  accountId,
  documentId,
}: DocumentInviteFormFieldValues): DocumentInviteEntity => {
  return {
    email: typeof email === 'string' ? email : email?.id === '-1' ? email.name : undefined,
    accountId,
    userId: typeof email === 'string' ? -1 : email?.id ? parseInt(email.id) : undefined,
    documentId,
  };
};

export function useDocumentInviteForm(loadedValues: DocumentInviteFormFieldValues) {
  return useForm<DocumentInviteFormFieldValues>({
    defaultValues: {
      email: null,
      accountId: -1,
      documentId: '',
    },
    mode: 'onTouched',
    values: loadedValues || undefined,
  });
}
