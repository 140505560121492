import { Skeleton } from '@mui/material';
import { DocumentChangeTransition } from '../DocumentChangeTransition';
import { Milestone, MilestoneContent, MilestoneDate, MilestoneInfo, Timeline } from '../timeline';

const numberOfItems = 8;
export function ActivitiesSkeleton() {
  return (
    <DocumentChangeTransition>
      <Timeline data-testid="insights-activities-skeleton">
        {new Array(numberOfItems).fill(null).map((_, index) => (
          <Milestone key={index} isTitleAlignedWithIcons={true} isLast={index === numberOfItems - 1}>
            <MilestoneDate>
              <Skeleton variant="rectangular" width={100} height={15} sx={{ marginBottom: '5px' }} />
            </MilestoneDate>
            <MilestoneContent>
              <Skeleton variant="circular" width={30} height={30} />
              <MilestoneInfo>
                <Skeleton variant="rectangular" width={275} height={20} />
                <Skeleton variant="rectangular" width={230} height={40} />
              </MilestoneInfo>
            </MilestoneContent>
          </Milestone>
        ))}
      </Timeline>
    </DocumentChangeTransition>
  );
}
