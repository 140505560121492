import { Endpoints } from 'utils/endpoints';
import { apiClient } from '../client';
import { PermissionsRepository } from './PermissionsRepository';
import { UserRolesApiResponse } from './interfaces';
import { AxiosError } from 'axios';

export class ApiPermissionsRepository implements PermissionsRepository {
  public async getUserRoles(accountId: number): Promise<UserRolesApiResponse> {
    try {
      return apiClient.get<UserRolesApiResponse>(Endpoints.getUserRoles(accountId));
    } catch (error) {
      const err = error as AxiosError;
      const data = err.response?.data ? (err.response.data as { state: boolean; message: string }) : { state: false, message: '' };
      return { state: data.state || false, message: data.message, roles: [] };
    }
  }
}
