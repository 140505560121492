import { Typography } from '@mui/material';

export function SectionTitleTypography({ sectionId, sectionTitle }: { sectionId: string; sectionTitle: string }) {
  return (
    <Typography
      id={`section-title-${sectionId}`}
      data-testid="section-title-typography"
      sx={{
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '24px',
        padding: '12px 0 12px 0',
        color: 'black',
      }}
    >
      {sectionTitle}
    </Typography>
  );
}
