import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Activities } from './activities/Activities';
import { Analytics } from './analytics/Analytics';
import { ContentNotAvailable } from './ContentNotAvailable';
import { useInsightsContext } from './InsightsProvider';

export function InsightsExpandedContent() {
  const { t } = useTranslation();
  const { hasErrors } = useInsightsContext();

  if (hasErrors) {
    return <ContentNotAvailable isExpanded />;
  }

  return (
    <Box padding="1.5rem 3.5rem" display="grid" gap="1.5rem" gridTemplateColumns="1.5fr 1fr">
      <Box sx={(theme) => ({ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) })}>
        <Analytics />
      </Box>
      <Card elevation={0} sx={{ height: 'fit-content' }}>
        <CardHeader title={t('pipeline.insights.tab_activity')} titleTypographyProps={{ variant: 'h6' }} sx={{ paddingBottom: 0 }} />
        <CardContent>
          <Activities />
        </CardContent>
      </Card>
    </Box>
  );
}
