import { Card, CardContent, FormControl, Paper, Skeleton, Typography } from '@mui/material';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { useAnalyticsContext } from './AnalyticsProvider';

export function ViewerFilter() {
  const { t } = useTranslation();
  const { selectedViewer, onSelectViewer, viewers, isLoading, isEmpty } = useAnalyticsContext();

  const handleChange = (event) => {
    onSelectViewer(event.target.value);
  };

  return (
    <Card elevation={0}>
      <CardContent>
        <Paper elevation={0} sx={(theme) => ({ backgroundColor: theme.palette.grey[100], padding: theme.spacing(2) })}>
          <FormControl disabled={isLoading || isEmpty} fullWidth>
            <Typography id="insights-viewer-filter-label" variant="body1" color="text.primary" fontWeight={600}>
              {t('pipeline.insights.analytics.filter_label')}
            </Typography>
            <Select
              sx={(theme) => ({
                marginTop: theme.spacing(0.5),
                backgroundColor: theme.palette.background.paper,
                border: 'none',
              })}
              data-testid="insights-viewer-filter"
              displayEmpty
              value={isLoading ? 'loading' : selectedViewer}
              onChange={handleChange}
            >
              <MenuItem value="all">{t('pipeline.insights.analytics.all_viewers')}</MenuItem>
              {isLoading && (
                <MenuItem disabled value="loading">
                  <Skeleton data-testid="analytics-viewer-filter-skeleton" variant="text" width="30%" height={20} />
                </MenuItem>
              )}
              {!isLoading &&
                !isEmpty &&
                viewers.map((viewer) => (
                  <MenuItem key={viewer.email} value={viewer.email}>
                    {viewer.name || viewer.email}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Paper>
      </CardContent>
    </Card>
  );
}
