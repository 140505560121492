import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAnalyticsContext } from './AnalyticsProvider';
import { ViewedSectionsChart } from './ViewedSectionsChart';
import { ViewedSectionsChartSkeleton } from './ViewedSectionsChartSkeleton';

export function ViewedSections() {
  const { t } = useTranslation();
  const { isLoading, viewedSections, lastUpdated, isEmpty } = useAnalyticsContext();

  return (
    <Card elevation={0}>
      <CardHeader
        title={t('pipeline.insights.analytics.top_five_viewed_sections')}
        titleTypographyProps={{ variant: 'h6' }}
        subheader={lastUpdated ? t('pipeline.insights.analytics.top_five_viewed_sections_last_updated', { lastUpdated }) : '.'}
        subheaderTypographyProps={{ variant: 'caption' }}
        data-testid="viewed-sections-chart-title"
        sx={{ paddingBottom: 0 }}
      />
      <CardContent>
        {isLoading && <ViewedSectionsChartSkeleton />}
        {!isLoading && isEmpty && (
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 250 }}>
            <Typography variant="h6" color="text.secondary" data-testid="viewed-sections-empty-state">
              {t('pipeline.insights.analytics.no_views_yet')}
            </Typography>
            <Typography variant="body1">{t('pipeline.insights.analytics.no_views_yet_description')}</Typography>
          </Box>
        )}
        {!isLoading && !isEmpty && <ViewedSectionsChart viewedSections={viewedSections} data-testid="viewed-sections-chart-presenter" />}
      </CardContent>
    </Card>
  );
}
