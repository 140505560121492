import { useFormatRelativeDateTime } from 'pages/pipeline/utils/useFormatRelativeDateTime';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ViewedSection } from 'services/analytics/AnalyticsRepository';
import { useInsightsContext } from '../InsightsProvider';
import { ActivitySummary, Analytics, ViewersList } from './types';
import { useQueryAnalytics } from './useQueryAnalytics';

export type AnalyticsContext = {
  selectedViewer: string;
  onSelectViewer: (viewer: string) => void;
  isLoading: boolean;
  viewedSections: ViewedSection[];
  viewers: ViewersList;
  lastUpdated: string;
  summary: ActivitySummary;
  isEmpty: boolean;
};

export const AnalyticsContext = createContext<AnalyticsContext | null>(null);

export const AnalyticsProvider = ({ children }: { children: React.ReactNode }) => {
  const { documentId } = useInsightsContext();
  const [selectedViewer, setSelectedViewer] = useState<string>('all');
  const { analytics, isLoading } = useQueryAnalytics();
  const { formatRelativeDateTime } = useFormatRelativeDateTime();

  useEffect(() => {
    setSelectedViewer('all');
  }, [documentId]);

  const mappedAnalytics: Analytics = useMemo(() => {
    return {
      ...analytics,
      updatedAt: analytics.updatedAt ? formatRelativeDateTime(analytics.updatedAt) : '',
      allViewers: {
        id: 'all' as const,
        viewedSections: analytics.viewers
          .flatMap((viewer) => viewer.viewedSections)
          .reduce((acc, section) => {
            const existingSection = acc.find((s) => s.id === section.id);
            if (existingSection) {
              existingSection.viewedTimeInSeconds += section.viewedTimeInSeconds;
            } else {
              acc.push({ ...section });
            }
            return acc;
          }, [] as ViewedSection[]),
        summary: {
          totalViews: analytics.viewers.reduce((total, viewer) => total + viewer.summary.totalViews, 0),
          totalViewTimeInSeconds: analytics.viewers.reduce((total, viewer) => total + viewer.summary.totalViewTimeInSeconds, 0),
          lastViewedAt: analytics.viewers.reduce(
            (latest, viewer) =>
              new Date(viewer.summary.lastViewedAt) > new Date(latest || '1900-01-01T00:00:00Z') ? viewer.summary.lastViewedAt : latest,
            ''
          ),
        },
      },
    };
  }, [analytics]);

  const mappedViewers: ViewersList = useMemo(() => {
    return mappedAnalytics.viewers.map((viewer) => ({ name: viewer.name, email: viewer.email }));
  }, [mappedAnalytics]);

  const onSelectViewer = useCallback(
    (viewer: string) => {
      if (viewer === 'all') {
        setSelectedViewer('all');
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const selected = mappedViewers.find((v) => v.email === viewer || v.name === viewer)!.email;
        setSelectedViewer(selected);
      }
    },
    [mappedViewers]
  );

  const viewedSections = useMemo(() => {
    return selectedViewer === 'all'
      ? mappedAnalytics.allViewers.viewedSections
      : mappedAnalytics.viewers.find((v) => v.email === selectedViewer)?.viewedSections;
  }, [mappedAnalytics, selectedViewer]);

  const summary = useMemo(() => {
    return selectedViewer === 'all'
      ? mappedAnalytics.allViewers.summary
      : mappedAnalytics.viewers.find((v) => v.email === selectedViewer)?.summary;
  }, [mappedAnalytics, selectedViewer]);

  return (
    <AnalyticsContext.Provider
      value={{
        isEmpty: mappedAnalytics.isEmpty,
        selectedViewer,
        onSelectViewer,
        isLoading,
        viewedSections: viewedSections || [],
        viewers: mappedViewers,
        lastUpdated: mappedAnalytics.updatedAt,
        summary: summary || {
          totalViews: 0,
          totalViewTimeInSeconds: 0,
          lastViewedAt: '',
        },
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalyticsContext = () => {
  const context = useContext<AnalyticsContext | null>(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalyticsContext must be used within an AnalyticsProvider');
  }
  return context;
};
