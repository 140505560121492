import { useMutation } from 'react-query';
import { ApiSeatsRepository } from 'services/api/seats/ApiSeatsRepository';
import { BasicApiResponse, DocumentInviteEntity } from 'services/api/seats/interfaces';

type DocumentInviteParam = {
  payload: DocumentInviteEntity;
};

export default function useDocumentInviteMutation() {
  const reactQuery = useMutation({
    mutationFn: documentInvite,
  });

  return { documentInvite: reactQuery.mutateAsync, documentInvitePromise: reactQuery.mutate, isLoading: reactQuery.isLoading };
}

const documentInvite = async (data: DocumentInviteParam): Promise<BasicApiResponse> => {
  const documentsApi = new ApiSeatsRepository();
  const response = await documentsApi.documentInvite(data.payload);

  return {
    state: response.state,
    message: response.message,
  };
};
