export const getInitials = (...args: string[]): string => {
  if (args.length === 1) {
    return args[0].substring(0, 2);
  }
  return args
    .filter(Boolean)
    .map((str) => str[0])
    .reduce((value, char) => value.toUpperCase() + char.toUpperCase(), '')
    .substring(0, 2);
};
