import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function useConvertDuration() {
  const { t } = useTranslation();

  const convertDuration = useCallback(
    (durationInSeconds: number) => {
      const hours = Math.floor(durationInSeconds / 3600);
      const minutes = Math.floor((durationInSeconds % 3600) / 60);
      const seconds = durationInSeconds % 60;

      const hoursDisplay = hours > 0 ? t('pipeline.utils.hours', { hours }) : '';
      const minutesDisplay = minutes > 0 ? t('pipeline.utils.minutes', { minutes }) : '';
      const secondsDisplay = seconds > 0 ? t('pipeline.utils.seconds', { seconds }) : '';

      return [hoursDisplay, minutesDisplay, secondsDisplay].filter(Boolean).join(' ') || '0s';
    },
    [t]
  );

  return {
    convertDuration,
  };
}
