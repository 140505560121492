import React from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import { EditorConfig } from '../helpers/config';
import { SectionToolbar } from './SectionToolbar';
import { useDocumentLockedModal } from '../modals/DocumentLockedModalProvider';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../grid/reduxStore/Store';
import { SectionIdProvider } from './SectionIdProvider';
import { AcknowledgmentResponseStatus, ErrorCode, SectionEvents } from '../../../services/socket/SocketEvents';
import { useSocketClient } from '../../../providers/SocketContext';
import { addSection, deleteSection } from '../grid/reduxStore/editorSlice';
import { openNotification } from '../../notification';
import { useTranslation } from 'react-i18next';
import { setSaveStatus } from '../grid/reduxStore/saveStatusSlice';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';
import LazyLoadComponent from '../LazyLoad/LazyLoadComponent';
const Canvas = React.lazy(() => import('./Canvas'));

interface EditorContentProps {
  documentId: string;
  setRef: (node: FroalaEditor | null) => void;
  editorConfig: EditorConfig;
  toolbarButtons?: any;
}

const EditorContent: React.FC<EditorContentProps> = ({ documentId, editorConfig }) => {
  const allSections = useSelector((state: RootState) => state.gridBlockReducer.sections);
  const { socketClient } = useSocketClient();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { checkDocumentLockStatus } = useDocumentLockedModal();

  const orderedSections = Object.values(allSections)
    .map((section) => ({
      id: section.id,
      order: section.order,
      ref: React.createRef<HTMLDivElement>(),
    }))
    .sort((a, b) => a.order - b.order);

  const handleAddButtonOnClick = (orderId: number) => {
    const payload = { order: orderId, title: t('editor.default_content_section_title') };
    socketClient.publish(SectionEvents.SECTION_ADD, payload, sectionAddCallback);
  };

  const sectionAddCallback = async (response) => {
    if (checkDocumentLockStatus(response.errorCode)) {
      dispatch(setSaveStatus({ status: DocumentSaveStatus.NOT_SAVED, errCode: ErrorCode.LOCKED }));
      return;
    }
    dispatch(
      addSection({ sectionId: response.content.sectionId, title: t('editor.default_content_section_title'), order: response.content.order })
    );
  };

  const enableDelete = orderedSections.length > 1;

  const handleDeleteButtonOnClick = (sectionId: string) => {
    socketClient.publish(SectionEvents.SECTION_DELETE, { sectionId: sectionId }, (response) => {
      if (response.status === AcknowledgmentResponseStatus.OK) {
        dispatch(deleteSection({ sectionId: sectionId }));
      } else {
        if (checkDocumentLockStatus(response.errorCode)) {
          dispatch(setSaveStatus({ status: DocumentSaveStatus.NOT_SAVED, errCode: ErrorCode.LOCKED }));
          return;
        }
        openNotification({
          type: 'error',
          title: t('editor.sections.delete_failed'),
          description: '',
          placement: 'top',
        });
      }
    });
  };

  return (
    <>
      {orderedSections.map((section) => (
        <SectionIdProvider key={section.id} sectionId={section.id}>
          <LazyLoadComponent>
            <Canvas
              data-testid={`section-${section.id}`}
              enableDelete={enableDelete}
              addButtonOnClick={() => handleAddButtonOnClick(section.order + 1)}
              key={section.id}
              configOptions={editorConfig}
              deleteButtonOnClick={() => handleDeleteButtonOnClick(section.id)}
              documentId={documentId}
              gridRef={section.ref}
              sectionOrder={section.order}
            />
          </LazyLoadComponent>
        </SectionIdProvider>
      ))}

      <SectionToolbar
        enableDelete={false}
        addButtonOnClick={() => handleAddButtonOnClick(orderedSections[orderedSections.length - 1].order + 1)}
      />
    </>
  );
};

export default EditorContent;
