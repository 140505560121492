import { useEffect } from 'react';

export type TUseSessionStorage<T> = [() => T | undefined, (value: T) => void, () => void];

/**
 * @description A hook that provides functions to interact with a session storage item.
 * @param key - The key of the session storage item.
 * @returns An array of functions to get, set and remove the session storage item.
 */
export function useSessionStorage<T>(key: string, initialValue?: T): TUseSessionStorage<T> {
  useEffect(() => {
    if (!getSessionStorageItem() && initialValue) {
      setSessionStorageItem(initialValue);
    }
  }, []);

  const setSessionStorageItem = (value: T): void => {
    try {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      window.console.error(error);
    }
  };

  const getSessionStorageItem = (): T | undefined => {
    try {
      const item = window.sessionStorage.getItem(key);
      if (item === null) return undefined;
      return JSON.parse(item);
    } catch (error) {
      window.console.error(error);
      return undefined;
    }
  };

  const removeSessionStorageItem = (): void => {
    try {
      window.sessionStorage.removeItem(key);
    } catch (error) {
      window.console.error(error);
    }
  };

  return [getSessionStorageItem, setSessionStorageItem, removeSessionStorageItem];
}
