import { useEffect, useRef } from 'react';
import { useSectionsForPreview } from '../../pages/editor/useSectionsForPreview';
import { SectionByDocumentIdApiResponse } from '../../services/documents/DocumentRepository';
import useScrollingDirection, { ScrollDirectionsEnum } from 'hooks/useScrollingDirection';

export const PREVIEW_SECTION_CONTAINER_CLASS = 'preview-section-container';
export const PREVIEW_CONTAINER_SELECTOR = 'main.preview__main';

const useTrackActiveSectionInPreview = (sections?: SectionByDocumentIdApiResponse[]) => {
  const { activeSectionId, setActiveSectionId } = useSectionsForPreview();
  const { startTrackingScrollDirection, stopTrackingScrollDirection, getCurrentScrollDirection } =
    useScrollingDirection(PREVIEW_CONTAINER_SELECTOR);
  const sectionsVisibilityMap = useRef<Map<string, number>>(new Map());

  const getActiveSectionElement = (scrollDirection: ScrollDirectionsEnum): string | null => {
    let mostVisibleElement: string | null = null;
    let maxRatio = 0;

    sectionsVisibilityMap.current.forEach((visibility, sectionId) => {
      const shouldUpdateActiveElement =
        (visibility > maxRatio && scrollDirection === ScrollDirectionsEnum.UP) ||
        (visibility >= maxRatio && scrollDirection === ScrollDirectionsEnum.DOWN);

      if (shouldUpdateActiveElement) {
        maxRatio = visibility;
        mostVisibleElement = sectionId;
      }
    });

    return mostVisibleElement;
  };

  const observeSectionsContainer = () => {
    const config = {
      root: document.querySelector(PREVIEW_CONTAINER_SELECTOR),
      rootMargin: '0px',
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    };

    const sectionObserver = new IntersectionObserver((entries) => {
      entries.forEach(function (entry) {
        const { intersectionRatio } = entry;
        const {
          dataset: { sectionId },
        } = entry.target as HTMLElement;

        if (sectionId) {
          sectionsVisibilityMap.current.set(sectionId, intersectionRatio);
        }

        const newActiveSectionId = getActiveSectionElement(getCurrentScrollDirection());
        if (newActiveSectionId) {
          setActiveSectionId(newActiveSectionId);
        }
      });
    }, config);

    const sectionsContainer = document.querySelectorAll(`.${PREVIEW_SECTION_CONTAINER_CLASS}`);
    sectionsContainer.forEach((aSectionContainer) => {
      sectionObserver.observe(aSectionContainer);
    });

    return () => sectionObserver.disconnect();
  };

  useEffect(() => {
    startTrackingScrollDirection();
    return () => {
      stopTrackingScrollDirection();
    };
  }, [sections]);

  useEffect(() => {
    const observerDisconnect = observeSectionsContainer();
    return () => {
      observerDisconnect();
    };
  }, [sections]);

  return { activeSectionId: sections ? activeSectionId : null };
};

export default useTrackActiveSectionInPreview;
