import { createIndexedDBHandler } from 'utils/indexeddb';
import { TrackingLogger } from './TrackingLogger';

const DATABASE_NAME = 'proposify';
const DATABASE_VERSION = 1;
const TABLE_NAME = 'preview-tracking';
const PRIMARY_KEY = 'id';

type PersistedTrackingData = {
  id: string;
  viewTimes: Map<string, number>;
  startedAt: number;
};

export const PreviewTrackingIndexedDB = createIndexedDBHandler<PersistedTrackingData, 'id'>({
  logger: TrackingLogger,
  databaseName: DATABASE_NAME,
  databaseVersion: DATABASE_VERSION,
  tableName: TABLE_NAME,
  primaryKey: PRIMARY_KEY,
});
