import { activitiesClient } from '../api';
import { ActivitiesRepository, ActivitiesResponse } from './ActivitiesRepository';
import { validateActivitiesResponse } from './validation';

const getActivitiesByDocumentId = async (documentId?: string): Promise<ActivitiesResponse> => {
  const response = await activitiesClient.get<ActivitiesResponse>(`/document/${documentId}`);
  validateActivitiesResponse(response);
  return response;
};

export const ApiActivitiesRepository: ActivitiesRepository = {
  getActivities: getActivitiesByDocumentId,
};
