import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { DocumentCollaboratorEntity } from 'services/api/seats/interfaces';
import { ApiSeatsRepository } from 'services/api/seats/ApiSeatsRepository';

type UseDocumentCollaboratorsApiDataParams = {
  documentId: string;
};

type UseDocumentCollaboratorsApiDataResult = {
  data?: DocumentCollaboratorEntity[] | null;
  isLoading: boolean;
  isError: boolean;
  isFetched: boolean;
  isRefetching: boolean;
  refetch;
};

type UseDocumentCollaboratorsApiData<P> = (params: P) => UseDocumentCollaboratorsApiDataResult;

export const useDocumentCollaboratorsApiData: UseDocumentCollaboratorsApiData<UseDocumentCollaboratorsApiDataParams> = ({ documentId }) => {
  const apiSeatsRepository = new ApiSeatsRepository();
  const { data, isLoading, isError, isFetched, refetch, isRefetching } = useQuery({
    queryKey: QueryKeys.getDocumentCollaborators(documentId),
    queryFn: () => apiSeatsRepository.getDocumentCollaborators(documentId),
  });

  return {
    data,
    isLoading,
    isError,
    isFetched,
    isRefetching,
    refetch,
  };
};
