import { Box, Card, CardContent, CardHeader, List, ListItem, ListItemText, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useConvertDuration } from '../../utils/useConvertDuration';
import { useFormatRelativeDateTime } from '../../utils/useFormatRelativeDateTime';
import { useAnalyticsContext } from './AnalyticsProvider';

export function ActivitySummary() {
  const { t } = useTranslation();
  const { summary, isLoading, isEmpty } = useAnalyticsContext();
  const { formatRelativeDateTime } = useFormatRelativeDateTime();
  const { convertDuration } = useConvertDuration();

  return (
    <Card elevation={0}>
      <CardHeader
        title={t('pipeline.insights.analytics.activity_summary')}
        titleTypographyProps={{ variant: 'h6' }}
        data-testid="activity-summary-title"
        sx={{ paddingBottom: 0 }}
      />
      <CardContent>
        {isLoading && <Skeleton data-testid="activity-summary-skeleton" variant="text" height={146} />}
        {!isLoading && (
          <List disablePadding>
            <ListItem divider>
              <ListItemText
                primary={
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>{t('pipeline.insights.analytics.total_views')}</Typography>
                    <Typography>{isEmpty ? 0 : summary.totalViews}</Typography>
                  </Box>
                }
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary={
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>{t('pipeline.insights.analytics.total_view_time')}</Typography>
                    <Typography>{isEmpty ? '–' : convertDuration(summary.totalViewTimeInSeconds)}</Typography>
                  </Box>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>{t('pipeline.insights.analytics.last_viewed')}</Typography>
                    <Typography>
                      {isEmpty ? t('pipeline.insights.analytics.no_views_yet') : formatRelativeDateTime(summary.lastViewedAt)}
                    </Typography>
                  </Box>
                }
              />
            </ListItem>
          </List>
        )}
      </CardContent>
    </Card>
  );
}
