import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { getEnvironment, isProduction } from 'utils/getEnvironment';

if (isProduction()) {
  datadogRum.onReady(() => {
    datadogRum.init({
      service: process.env.REACT_APP_DD_RUM_SERVICE ?? 'core',
      site: 'datadoghq.com',

      applicationId: process.env.REACT_APP_DD_RUM_REACT_FE_CLIENT_APP_ID ?? '',
      clientToken: process.env.REACT_APP_DD_RUM_REACT_FE_CLIENT_TOKEN ?? '',

      env: getEnvironment(),
      version: COMMIT_SHA || 'unknown_sha', // COMMIT_SHA is loaded from the build process, see craco.config.js

      sessionSampleRate: 50, // 50% of sessions will be sampled
      sessionReplaySampleRate: 0, // Disable session replay
      trackUserInteractions: false, // Disable automatic interaction tracking

      trackResources: true, // useful to improve loading times
      trackLongTasks: true, // useful to find if there are slow downs or freezes

      // in order to trace requests to our backends, we must enable the following in the backend:
      // access-control-allow-headers: x-datadog-sampled, x-datadog-parent-id,  x-datadog-trace-id, x-datadog-origin, x-datadog-sampling-priority, traceparent

      // TODO: Uncomment this when our backends accept new tracing headers
      // allowedTracingUrls: [/https:\/\/.*\.proposify\.(com|link|net)/],
    });
  });
}

export function DatatogTracker({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    if (!isProduction()) return;

    if (navigator.language) {
      datadogRum.setUser({
        language: navigator.language,
      });
    }
  }, []);

  return <>{children}</>;
}
