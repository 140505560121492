import React, { useState, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import DataSourceSelect from './components/DataSourceSelect';
import { SelectChangeEvent } from '@mui/material';
import ObjectTypeTabs from './components/ObjectTypeTabs';
import { useTopLevelObjectType } from './useTopLevelObjectType';
import { useParams } from 'react-router-dom';
import { useFlexDataLink } from './useFlexDataLink';
import { Params } from './types';
import FlexDataLinkSkeleton from './components/FlexDataLinkSkeleton';

const FlexDataLinkPage: React.FC = () => {
  const { documentId } = useParams<keyof Params>() as Params;

  const { isTopLevelObjectTypesLoading, topLevelObjectTypes = [], isTopLevelObjectTypesError, status } = useTopLevelObjectType();

  const { isFlexDataLinkLoading, flexDataLinks = [], isFlexDataLinkError } = useFlexDataLink(documentId);

  const [dataSourceType, setDataSourceType] = useState('');

  useEffect(() => {
    if (topLevelObjectTypes.length > 0) {
      setDataSourceType(topLevelObjectTypes[0]?.dataSourceType || '');
    }
  }, [topLevelObjectTypes]);

  const dataSourceTypes = useMemo(
    () =>
      topLevelObjectTypes.map(({ dataSourceType, dataSourceName }) => ({
        dataSourceType,
        dataSourceName,
      })),
    [topLevelObjectTypes]
  );

  const selectedDataSource = useMemo(
    () => topLevelObjectTypes.find((item) => item.dataSourceType === dataSourceType),
    [dataSourceType, topLevelObjectTypes]
  );

  const objectTypes = selectedDataSource?.objectTypes || [];

  const handleDataSourceTypeChange = (event: SelectChangeEvent) => {
    setDataSourceType(event.target.value as string);
  };
  if (status === 'success' && topLevelObjectTypes.length <= 0) {
    return (
      <Box padding={2} data-testid="error-container">
        <p>No data available.</p>
      </Box>
    );
  }
  if (isTopLevelObjectTypesLoading || isFlexDataLinkLoading) {
    return <FlexDataLinkSkeleton />;
  }

  if (isTopLevelObjectTypesError || isFlexDataLinkError) {
    return (
      <Box padding={2} data-testid="error-container">
        <p>Error loading data. Please try again later.</p>
      </Box>
    );
  }

  return (
    <Box padding={2} sx={{ width: '100%' }}>
      <DataSourceSelect dataSourceTypes={dataSourceTypes} onChange={handleDataSourceTypeChange} selectedDataSourceType={dataSourceType} />
      <ObjectTypeTabs parentDataSourceType={dataSourceType} objectTypes={objectTypes} flexDataLinks={flexDataLinks} />
    </Box>
  );
};

export default FlexDataLinkPage;
