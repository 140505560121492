export enum Environment {
  Development = 'development',
  RC = 'rc',
  QA = 'qa',
  Production = 'production',
}

export function getEnvironment(): Environment {
  if (process.env.REACT_APP_ENV === 'development') {
    return Environment.Development;
  }

  if (process.env.REACT_APP_COOKIE_DOMAIN?.includes('proposify.net')) {
    return Environment.RC;
  }

  if (process.env.REACT_APP_COOKIE_DOMAIN?.includes('proposify.link')) {
    return Environment.QA;
  }

  return Environment.Production;
}

export function isProduction(): boolean {
  return getEnvironment() === Environment.Production;
}
