import { Box, Button, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import {
  useAcceptInvitationForm,
  SubmitHandler,
  transformAcceptInvitationFieldValuesToApiPayload,
  AcceptInvitationFormFieldValues,
} from './useAcceptInvitationForm';
import FormField from '../settings/utils/FormField';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { AcceptInvitationApiResponse } from 'services/api/account/interfaces';
import useAcceptInvitationMutation from 'services/api/account/hooks/useAcceptInvitationMutation';

interface AcceptInvitationFormProps {
  onSuccess: () => void;
  onFailure: (response: AcceptInvitationApiResponse) => void;
  initialData?: AcceptInvitationFormFieldValues;
  disabled?: boolean;
}

const AcceptInvitationForm = ({ onSuccess, onFailure, initialData, disabled = false }: AcceptInvitationFormProps) => {
  const { t } = useTranslation();
  const { handleSubmit, control } = useAcceptInvitationForm(initialData);
  const { acceptInvitation, isLoading: isUpdating } = useAcceptInvitationMutation();

  const onSubmit: SubmitHandler = useCallback(
    async (submitData) => {
      const payload = transformAcceptInvitationFieldValuesToApiPayload(submitData);
      const response = await acceptInvitation(payload);
      if (!response.state) {
        onFailure(response);
        return;
      }
      onSuccess();
    },
    [transformAcceptInvitationFieldValuesToApiPayload, acceptInvitation, onSuccess, onFailure]
  );

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      data-testid="accept-invitation-form"
      sx={{ minWidth: '408px', margin: '0 auto' }}
    >
      <Stack direction="column" sx={{ pt: '5px', gap: 3 }}>
        <Stack direction="row" gap={2} justifyContent="space-between" alignContent="center">
          <FormField<AcceptInvitationFormFieldValues>
            id="email"
            label={t('authentication.accept_invitation_form.email')}
            placeholder={t('authentication.accept_invitation_form.email_example')}
            control={control}
            disabled={!!initialData?.email}
            rules={{
              required: t('required_field'),
              pattern: {
                value: /[a-z0-9._%+!$&*=^|~#%'`?{}/-]+@([a-z0-9-]+\.){1,}([a-z]{2,16})/g,
                message: t('authentication.accept_invitation_form.email_invalid'),
              },
            }}
          />
        </Stack>
        <Stack direction="row" gap={2} justifyContent="space-between" alignContent="center">
          <FormField<AcceptInvitationFormFieldValues>
            id="firstName"
            label={t('authentication.accept_invitation_form.first_name')}
            placeholder={t('authentication.accept_invitation_form.first_name')}
            control={control}
            rules={{
              required: t('required_field'),
            }}
          />
          <FormField<AcceptInvitationFormFieldValues>
            id="lastName"
            label={t('authentication.accept_invitation_form.last_name')}
            placeholder={t('authentication.accept_invitation_form.last_name')}
            control={control}
            rules={{
              required: t('required_field'),
            }}
          />
        </Stack>
        <Stack direction="row" gap={2} justifyContent="space-between" alignContent="center">
          <FormField<AcceptInvitationFormFieldValues>
            id="password"
            type="password"
            label={t('authentication.accept_invitation_form.password')}
            placeholder={t('authentication.accept_invitation_form.password')}
            control={control}
            rules={{
              required: t('required_field'),
              minLength: { value: 8, message: 'Must be at least 8 characters long' },
            }}
          />
        </Stack>
        <Stack direction="row" gap={2} justifyContent="space-between" alignContent="center">
          <FormField<AcceptInvitationFormFieldValues>
            id="receiveEmails"
            type="checkbox"
            label={t('authentication.accept_invitation_form.receive_emails')}
            control={control}
            shrinkLabel={false}
          />
        </Stack>
        <FormField<AcceptInvitationFormFieldValues> id="token" type="hidden" control={control} />
      </Stack>
      <Box pt={3} sx={{ display: 'flex' }}>
        <Button
          type="submit"
          disabled={disabled}
          variant="contained"
          size="large"
          data-testid="accept-invitation-form-submit"
          sx={{ width: '100%' }}
        >
          {isUpdating ? t('authentication.accept_invitation_form.signing_in') : t('authentication.accept_invitation_form.create_account')}
        </Button>
      </Box>
      <Box mt="12px" textAlign="center">
        <Typography variant="caption" color="text.primary">
          <Trans
            i18nKey="authentication.accept_invitation_form.terms_of_service"
            components={{
              terms: <Link title="Terms of service link" to="https://www.proposify.com/company/terms-of-services" target="_blank" />,
              policy: <Link title="Privacy policy link" to="https://www.proposify.com/company/privacy" target="_blank" />,
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
};

export default AcceptInvitationForm;
