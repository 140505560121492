import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Close from '@mui/icons-material/Close';
import useUnassignSeatMutation from 'services/api/seats/hooks/useUnassignSeatMutation';
import { useContext, useState } from 'react';
import { PlanSummaryContext } from './PlanSummaryProvider';
import { ErrorOutline } from '@mui/icons-material';

interface SeatsUnassignModalProps {
  isOpen: boolean;
  setIsOpen: (boolean) => void;
  onSuccess: () => void;
  userId?: number;
  userName?: string;
  seatType: 'user' | 'collaborator';
}

const SeatsUnassignModal = ({ isOpen, setIsOpen, onSuccess, userId, userName, seatType }: SeatsUnassignModalProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');
  const { accountId } = useContext(PlanSummaryContext);
  const { unassignSeat, isLoading } = useUnassignSeatMutation();

  const onConfirmUnassign = async () => {
    setError('');
    const response = await unassignSeat({
      accountId,
      payload: {
        accountId,
        seatType,
        userId: userId || -1,
      },
    });
    if (!response.state) {
      setError(response.message);
      return;
    }
    onSuccess();
  };

  return (
    <Dialog open={isOpen} data-testid="seat-unassign-modal">
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{t('settings.summary.seat_unassign.title', { name: userName || 'user' })}</Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsOpen(false);
            }}
            sx={(theme) => ({
              color: theme.palette.grey[500],
              padding: '5px',
            })}
            data-testid="seat-unassign-modal-close"
          >
            <Close sx={{ height: '20px', width: '20px' }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {userName
            ? t('settings.summary.seat_unassign.are_you_sure_with_name', { name: userName })
            : t('settings.summary.seat_unassign.are_you_sure')}
        </Typography>

        {error && <Alert sx={{ mt: 1 }} icon={<ErrorOutline />} severity="error" variant="filled" content={error} />}
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} variant="text" onClick={() => setIsOpen(false)}>
          {t('settings.summary.seat_unassign.cancel')}
        </Button>
        <Button disabled={isLoading} variant="contained" onClick={onConfirmUnassign}>
          {t('settings.summary.seat_unassign.yes_unassign_seat')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SeatsUnassignModal;
