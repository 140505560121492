import { InfoOutlined } from '@mui/icons-material';
import { Stack, Typography, Tooltip, Divider, Skeleton } from '@mui/material';
import { PreviewBasicApiResponse } from 'services/api/seats/interfaces';
import { formatAsPrice } from '../utils/utils';
import { useTranslation } from 'react-i18next';

interface SeatsAddConfirmationProps {
  previewBillingData: PreviewBasicApiResponse | undefined;
  isLoading?: boolean;
}

const getDurationInterval = (duration: number): string => {
  switch (duration) {
    case 12:
      return 'monthly';
    case 3:
      return '4 months';
    case 1:
      return '1 year';
    default:
      return 'Monthly';
  }
};

const SeatsAddConfirmation = ({ previewBillingData, isLoading = false }: SeatsAddConfirmationProps) => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" gap={2} pt={2}>
      {isLoading && (
        <>
          <Stack direction="row" justifyContent="space-between" key="seats-add-confirmation-line-item-empty1">
            <Skeleton width="50%" height="24px" variant="rounded" />
            <Skeleton width="25%" height="24px" variant="rounded" />
          </Stack>
          <Stack direction="row" justifyContent="space-between" key="seats-add-confirmation-line-item-empty1">
            <Skeleton width="50%" height="24px" variant="rounded" />
            <Skeleton width="25%" height="24px" variant="rounded" />
          </Stack>
        </>
      )}
      {!isLoading &&
        previewBillingData?.preview?.lineItems &&
        previewBillingData.preview.lineItems.map((lineItem, key) => (
          <Stack direction="row" justifyContent="space-between" key={`seats-add-confirmation-line-item-${key}`}>
            <Typography variant="body1">
              {t('settings.summary.seat_add.order_line_item', {
                price: formatAsPrice(lineItem.pricePerItem / (lineItem.duration == 12 ? 1 : lineItem.duration), true),
                quantity: lineItem.quantity,
                type: lineItem.quantity > 1 ? `${lineItem.type.toLowerCase()}s` : lineItem.type.toLowerCase(),
                duration: t('settings.summary.seat_add.duration_unit', getDurationInterval(lineItem.duration)),
              })}
            </Typography>
            <Typography variant="body1">{formatAsPrice(lineItem.total, false)}</Typography>
          </Stack>
        ))}
      <Stack direction="row" alignContent="center" justifyContent="space-between">
        <Stack direction="row" gap="4px" alignContent="center">
          <Typography variant="body1">{t('settings.summary.seat_add.proration')}</Typography>
          <Tooltip title={t('settings.summary.seat_add.proration_tooltip')}>
            <InfoOutlined />
          </Tooltip>
        </Stack>
        {isLoading ? (
          <Skeleton width="25%" height="24px" variant="rounded" />
        ) : (
          <Typography variant="body1">-{formatAsPrice(previewBillingData?.preview?.proration || 0)}</Typography>
        )}
      </Stack>
      <Divider />
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">{t('settings.summary.seat_add.subtotal')}</Typography>

        {isLoading ? (
          <Skeleton width="25%" height="24px" variant="rounded" />
        ) : (
          <Typography variant="body1">{formatAsPrice(previewBillingData?.preview?.subtotal || 0, false)}</Typography>
        )}
      </Stack>
      <Divider />
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column">
          <Typography variant="body1" fontWeight="700">
            {t('settings.summary.seat_add.total_due_today')}
          </Typography>
        </Stack>
        <Stack direction="column">
          {isLoading ? (
            <Skeleton width="100%" height="24px" variant="rounded" />
          ) : (
            <Typography variant="h5" textAlign="right">
              {formatAsPrice(previewBillingData?.preview?.total || 0)} USD
            </Typography>
          )}
          <Typography variant="caption" textAlign="right">
            {t('settings.summary.seat_add.plus_tax')}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SeatsAddConfirmation;
