import { useContext, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { TableSettings } from '../../../component';
import SvgIcoTableRowPlugsAfter from 'components/icon/icons/IcoTableRowPlusAfter';
import SvgIcoTableRowPlugsBefore from 'components/icon/icons/IcoTableRowPlusBefore';
import IcoHideElement from 'components/icon/icons/IcoHideElement';
import IcoShowElement from 'components/icon/icons/IcoShowElement';
import SvgIcoContentCopy from 'components/icon/icons/IcoContentCopy';

import { setActiveTableSettingsPanel } from '../../../../grid/reduxStore/blockStyleSettingsSlice';
import { RootState, useAppDispatch } from '../../../../grid/reduxStore/Store';
import { TotalRowTypesIdentifier } from 'components/editor/grid/reduxStore/table.types';
import { SelectionContext } from 'components/editor/GridDndEditor/SelectedBlockInfoProvider';
import { useSelector } from 'react-redux';
import { selectContentTable } from '../../../../grid/reduxStore/editorSlice';
import SvgIcoTrash from '../../../../../icon/icons/IcoTrash';
import { TableRowAddPositions, useTableRowManipulation } from '../RowSettings/useTableRowManipulation';
import { setOfRowsForPricingTable } from '../../../../GridDndEditor/Block/Table/defaultData';
import { FormHelperText } from '@mui/material';
import { useBlockContentChangedHandler } from '../../../../hooks/UseBlockContentChangedHandler';

const FooterRowSettings = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { handleRowAdd, handleRowDuplication, handleDeleteTotalRow } = useTableRowManipulation();
  const menuTitleTotalRows = t('document.grid.table.setting.total_rows');
  const subtotalRowHiddenTooltip = t('document.grid.table.setting.tooltip_hide_subtotal');
  const blockContentChangedHandler = useBlockContentChangedHandler();

  const { selectedBlockIdByWrapper, selectedSectionId } = useContext(SelectionContext);
  const sectionId = selectedSectionId as string;
  const blockId = selectedBlockIdByWrapper as string;
  const tableData = useSelector((state: RootState) => selectContentTable(state, sectionId, blockId)) || [];
  const { selectedRow, totalRows } = tableData;
  const [selectValue, setSelectValue] = useState(selectedRow?.rowType || '');
  const selectedRowType = selectedRow?.rowType;
  const isSelectedRowTypeTotal = selectedRowType === TotalRowTypesIdentifier.TOTAL;

  const [showRow, setShowRow] = useState(!!selectedRow?.isRowHidden);

  const handleHideSubtotalRow = async () => {
    setShowRow(!showRow);
  };

  const rowButtons = [
    {
      icon: <SvgIcoTableRowPlugsAfter />,
      disabled: isSelectedRowTypeTotal,
      arialLabel: 'row-plus-after',
      fn: () => handleRowAdd(TableRowAddPositions.AFTER),
    },
    {
      icon: <SvgIcoTableRowPlugsBefore />,
      arialLabel: 'row-plus-before',
      fn: () => handleRowAdd(TableRowAddPositions.BEFORE),
    },
    {
      icon: <SvgIcoContentCopy />,
      disabled: isSelectedRowTypeTotal,
      arialLabel: 'content-copy',
      fn: () => handleRowDuplication(),
    },
    {
      icon: <SvgIcoTrash />,
      disabled: isSelectedRowTypeTotal,
      arialLabel: 'trash',
      fn: () => handleDeleteTotalRow(),
    },
  ];

  const subtotalRowButtons = [
    {
      icon: <SvgIcoTableRowPlugsAfter />,
      arialLabel: 'row-plus-after',
      fn: () => handleRowAdd(TableRowAddPositions.AFTER),
    },
    {
      icon: <SvgIcoTableRowPlugsBefore />,
      arialLabel: 'row-plus-before',
      disabled: true,
      fn: () => handleRowAdd(TableRowAddPositions.BEFORE),
    },
    {
      icon: <SvgIcoContentCopy />,
      arialLabel: 'content-copy',
      disabled: true,
      fn: () => handleRowDuplication(),
    },
    {
      icon: showRow ? <IcoHideElement data-testid="icon-hide" /> : <IcoShowElement data-testid="icon-show" />,
      arialLabel: 'hide-row',
      fn: () => handleHideSubtotalRow(),
    },
  ];

  const activeRowButtons = selectedRowType === TotalRowTypesIdentifier.SUBTOTAL ? subtotalRowButtons : rowButtons;
  const selectOptions = {};
  setOfRowsForPricingTable?.forEach((row) => (selectOptions[row.rowType.toLowerCase()] = row.rowType));
  const menuTitle = menuTitleTotalRows;
  const selectDisabled = useMemo(() => {
    const selectedRowType = selectedRow?.rowType;
    return selectedRowType === TotalRowTypesIdentifier.SUBTOTAL || selectedRowType === TotalRowTypesIdentifier.TOTAL;
  }, [selectedRow]);

  const handleRowTypeChange = async (e) => {
    const { value } = e.target;
    if (selectedRow && value !== selectedRow.rowType && blockId) {
      let updatedSelectedRow = selectedRow;
      const updatedRows = totalRows?.map((row) => {
        if (row.id === selectedRow.id) {
          updatedSelectedRow = { ...row, price: '', rowType: value };
          return updatedSelectedRow;
        }
        return row;
      });
      setSelectValue(value);

      await blockContentChangedHandler(blockId, sectionId, {
        ...tableData,
        totalRows: updatedRows,
        metadata: {
          ...tableData.metadata,
        },
        selectedRow: updatedSelectedRow,
      });
    }
  };

  const handleBackButtonClick = () => {
    dispatch(setActiveTableSettingsPanel({ type: null }));
  };

  const checkDisabled = (option: string): boolean => {
    if (option === TotalRowTypesIdentifier.SUBTOTAL) {
      return true;
    }
    return option === TotalRowTypesIdentifier.TOTAL;
  };

  useEffect(() => {
    if (selectedRow) {
      setShowRow(selectedRow?.isRowHidden ?? false);
      setSelectValue(selectedRow.rowType);
    }
  }, [selectedRow]);

  return (
    <TableSettings
      iconButtons={activeRowButtons}
      handleBackButtonClick={handleBackButtonClick}
      handleSelectChange={handleRowTypeChange}
      helpText={t('document.grid.table.setting.update_select_row_type')}
      panelTitle={t('document.grid.table.setting.row_type')}
      selectId="select-row-type"
      selectOptions={selectOptions}
      checkDisabled={checkDisabled}
      selectValue={selectValue}
      menuTitle={menuTitle}
      testId="table-row-settings"
      disabled={selectDisabled}
    >
      {showRow && <FormHelperText data-testid={'subtotal-row-hidden-help-text'}>{subtotalRowHiddenTooltip}</FormHelperText>}
    </TableSettings>
  );
};

export default FooterRowSettings;
