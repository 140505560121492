import { useMutation } from 'react-query';
import { ApiSeatsRepository } from 'services/api/seats/ApiSeatsRepository';
import { BasicApiResponse, RemoveSeatEntity } from 'services/api/seats/interfaces';

type RemoveSeatParam = {
  accountId: number;
  payload: RemoveSeatEntity;
};

export default function useRemoveSeatMutation() {
  const reactQuery = useMutation({
    mutationFn: removeSeat,
  });

  return { removeSeat: reactQuery.mutateAsync, removeSeatPromise: reactQuery.mutate, isLoading: reactQuery.isLoading };
}

const removeSeat = async (data: RemoveSeatParam): Promise<BasicApiResponse> => {
  const seatsApi = new ApiSeatsRepository();
  const response = await seatsApi.removeSeat(data.payload);

  return {
    state: response.state,
    message: response.message,
  };
};
