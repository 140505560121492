import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Chip,
  IconButton,
  Skeleton,
  Alert,
  Typography,
  Box,
} from '@mui/material';
import React, { useContext, useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useBillingHistoryApiData } from 'hooks/useBillingHistoryApiData';
import { PlanSummaryContext } from './PlanSummaryProvider';
import { FileDownload } from '@mui/icons-material';
import { BillingHistoryEntity } from 'services/api/account/interfaces';
import { BillingCard, BillingCardContent } from '../utils/styled';
import BillingCardHeader from '../utils/BillingCardHeader';

const statusMapping: { [key: string]: 'success' | 'info' | 'error' } = {
  Paid: 'success',
  Unpaid: 'info',
  PastDue: 'error',
  Failed: 'error',
};

interface Pagination {
  rowsPerPage: number;
  cursor: string;
}

const InvoiceHistory: React.FC = () => {
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(0);
  const [pagination, setPagination] = useState<Pagination>({
    rowsPerPage: 5,
    cursor: '',
  });

  const [history, setHistory] = useState<BillingHistoryEntity[]>([]);
  const { accountId, accountRepository } = useContext(PlanSummaryContext);

  const addHistoryItem = (historyItem: BillingHistoryEntity) => {
    setHistory([...history, historyItem]);
  };

  const handleChangePage = (event, newPage) => {
    if (history[newPage]) {
      setPage(newPage);
      return;
    }
    setPage(newPage);
    setPagination({ ...pagination, cursor: nextPageCursor });
  };

  const handleRowChange = (event) => {
    setPagination({ cursor: '', rowsPerPage: Number(event.target.value) });
    setPage(0);
    setHistory([]);
  };

  const {
    data: billingHistoryData,
    isLoading: isLoadingBillingHistory,
    isFetched: isBillingHistoryFetched,
  } = useBillingHistoryApiData({
    accountRepository,
    accountId,
    pagination: { numPerPage: pagination.rowsPerPage, cursor: pagination.cursor },
  });

  const nextPageCursor = useMemo<string>(() => {
    return billingHistoryData?.cursor || '';
  }, [billingHistoryData]);

  useEffect(() => {
    if (!billingHistoryData) {
      return;
    }

    if (!history[page]) {
      addHistoryItem(billingHistoryData);
    }
  }, [billingHistoryData]);

  if (isBillingHistoryFetched && !isLoadingBillingHistory && billingHistoryData?.invoices.length === 0) {
    return (
      <Box px={3} width="100%">
        <Alert severity={!billingHistoryData?.state ? 'error' : 'info'} sx={{ width: '100%' }}>
          <Typography variant="body2">
            {t(!billingHistoryData?.state ? 'settings.summary.invoice_history.error' : 'settings.summary.invoice_history.no_invoices')}
          </Typography>
        </Alert>
      </Box>
    );
  }

  return (
    <BillingCard data-testid="invoice-history" sx={{ px: 0 }}>
      <BillingCardHeader addSidePadding title={t('settings.summary.invoice_history.title')} />
      <BillingCardContent sx={{ gap: 2, width: '100%', px: 0 }}>
        <TableContainer data-testid="invoice-history-table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant="head" sx={{ width: '160px', pl: 3 }}>
                  {t('settings.summary.invoice_history.date_issued')}
                </TableCell>
                <TableCell variant="head">{t('settings.summary.invoice_history.plan')}</TableCell>
                <TableCell variant="head" align="right" sx={{ width: '100px' }}>
                  {t('settings.summary.invoice_history.number_of_seats')}
                </TableCell>
                <TableCell variant="head" align="right" sx={{ width: '140px' }}>
                  {t('settings.summary.invoice_history.invoice_total')}
                </TableCell>
                <TableCell variant="head" align="center" sx={{ width: '117px' }}>
                  {t('settings.summary.invoice_history.status')}
                </TableCell>
                <TableCell variant="head" sx={{ width: '56px', pr: 3 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.length === 0 || isLoadingBillingHistory || !history[page] ? (
                <>
                  {Array.from(Array(pagination.rowsPerPage), (_, idx) => (
                    <TableRow key={`row-empty-${idx}`} data-testid={'invoice-history-invoice-skeleton'}>
                      <TableCell variant="body" sx={{ width: '160px', pl: 3 }}>
                        <Skeleton width="130px" height="32px" variant="rounded" />
                      </TableCell>
                      <TableCell variant="body">
                        <Skeleton width="200px" height="32px" variant="rounded" />
                      </TableCell>
                      <TableCell variant="body" align="right" sx={{ width: '100px' }}>
                        <Skeleton width="70px" height="32px" variant="rounded" />
                      </TableCell>
                      <TableCell variant="body" align="right" sx={{ width: '140px' }}>
                        <Skeleton width="110px" height="32px" variant="rounded" />
                      </TableCell>
                      <TableCell variant="body" align="right" sx={{ width: '125px' }}>
                        <Skeleton width="105px" height="32px" variant="rounded" />
                      </TableCell>
                      <TableCell variant="body" align="center" sx={{ width: '56px', verticalAlign: 'center', py: '10px', pr: 3 }}>
                        <Skeleton width="32px" height="32px" variant="rounded" />
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                history[page].invoices.map((invoice, key) => (
                  <TableRow key={key} data-testid={'invoice-history-invoice-row'}>
                    <TableCell variant="body" sx={{ width: '160px', pl: 3 }}>
                      {dayjs(invoice.dateObj.date).format('MMM D, YYYY')}
                    </TableCell>
                    <TableCell variant="body">{invoice.plan}</TableCell>
                    <TableCell variant="body" align="right" sx={{ width: '100px' }}>
                      {invoice.quantity}
                    </TableCell>
                    <TableCell variant="body" align="right" sx={{ width: '140px' }}>
                      {invoice.amount.replace('$-', '-$').replace('$0.0', '$0')}
                    </TableCell>
                    <TableCell variant="body" align="right" sx={{ width: '125px' }}>
                      <Chip
                        label={
                          invoice.credit
                            ? t('settings.summary.invoice_history.states.credit')
                            : t(`settings.summary.invoice_history.states.${invoice.state.toLowerCase()}`)
                        }
                        variant="filled"
                        color={!invoice.credit ? statusMapping[invoice.state] : 'info'}
                        sx={{ width: '105px' }}
                        data-testid={`invoice-history-status-${key}`}
                      />
                    </TableCell>
                    <TableCell variant="body" align="center" sx={{ width: '56px', verticalAlign: 'center', py: '10px', pr: 3 }}>
                      <IconButton
                        download
                        href={invoice.pdf}
                        target="_blank"
                        sx={{ '&:link': { padding: 1, display: 'block' } }}
                        data-testid={`invoice-history-invoice-download-${key}`}
                      >
                        <FileDownload color="action" sx={{ width: '24px', height: '24px' }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={isLoadingBillingHistory && page > 0 ? history[page - 1].total : history[page]?.total || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={pagination.rowsPerPage}
            rowsPerPageOptions={[5, 10, 15, 20, 25]}
            onRowsPerPageChange={handleRowChange}
            disabled={isLoadingBillingHistory}
          />
        </TableContainer>
      </BillingCardContent>
    </BillingCard>
  );
};

export default InvoiceHistory;
