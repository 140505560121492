import { useMutation } from 'react-query';
import { ApiSeatsRepository } from 'services/api/seats/ApiSeatsRepository';
import { BasicApiResponse, UnassignSeatEntity } from 'services/api/seats/interfaces';

type UnassignSeatParam = {
  accountId: number;
  payload: UnassignSeatEntity;
};

export default function useUnassignSeatMutation() {
  const reactQuery = useMutation({
    mutationFn: unassignSeat,
  });

  return { unassignSeat: reactQuery.mutateAsync, unassignSeatPromise: reactQuery.mutate, isLoading: reactQuery.isLoading };
}

const unassignSeat = async (data: UnassignSeatParam): Promise<BasicApiResponse> => {
  const seatsApi = new ApiSeatsRepository();
  const response = await seatsApi.unassignSeat(data.payload);

  return {
    state: response.state,
    message: response.message,
  };
};
