import { useForm, SubmitHandler as SubmitHandlerT } from 'react-hook-form';
import { SeatInviteEntity, SeatInviteMultipleEntity, SeatInviteMultipleRow } from 'services/api/seats/interfaces';

export type SeatsInviteFormFieldValues = {
  email: string;
  seatType: 'user' | 'collaborator';
  roleId?: number;
  accountId: number;
};

export type SeatsInviteMultipleFormFieldValues = {
  invites: SeatInviteMultipleRow[];
  accountId: number;
};

export type SubmitHandler = SubmitHandlerT<SeatsInviteFormFieldValues>;

export type SubmitMultipleHandler = SubmitHandlerT<SeatsInviteMultipleFormFieldValues>;

export const transformSeatsFieldValuesToApiPayload = ({
  email,
  accountId,
  seatType,
  roleId = -1,
}: SeatsInviteFormFieldValues): SeatInviteEntity => {
  return {
    email,
    accountId,
    seatType,
    roleId,
  };
};

export const transformInvitesFieldValuesToApiPayload = ({
  invites,
  accountId,
}: SeatsInviteMultipleFormFieldValues): SeatInviteMultipleEntity => {
  return {
    invites,
    accountId,
  };
};

export function useSeatsInviteForm(loadedValues: SeatsInviteFormFieldValues) {
  return useForm<SeatsInviteFormFieldValues>({
    defaultValues: {
      email: '',
      accountId: -1,
    },
    mode: 'onTouched',
    values: loadedValues || undefined,
  });
}

export function useSeatsInviteMultipleForm(loadedValues: SeatsInviteMultipleFormFieldValues) {
  return useForm<SeatsInviteMultipleFormFieldValues>({
    defaultValues: {
      invites: [],
      accountId: -1,
    },
    mode: 'onChange',
    values: loadedValues || undefined,
  });
}
