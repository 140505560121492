import { AxiosError } from 'axios';
import { apiClient } from 'services/api';
import { Endpoints } from 'utils/endpoints';
import {
  ApplyPromoCodeApiResponse,
  CancelPlanApiResponse,
  CancelPlanPayload,
  CancelReasonsApiResponse,
  CancelReasonsEntity,
  BillingInterval,
  PlanApiResponse,
  PlanEntity,
  UpdatePlanApiResponse,
  UpdatePlanParam,
} from './interfaces';
import { PlanRepository } from './PlanRepository';
import { getPlanTierByString } from './utils';

export class ApiPlanRepository implements PlanRepository {
  public async getAccountPlan(accountId: number): Promise<PlanEntity> {
    const plan = await apiClient.get<PlanApiResponse>(Endpoints.getAccountPlan(accountId));
    const {
      is_trial,
      pausable,
      id,
      custom,
      type,
      name,
      can_self_downgrade,
      isSwitchingToTeamPlanADowngrade,
      seats_taken,
      total_seats,
      is_trial_expired,
      billing_interval_length,
      estimated_renewal_total,
      price,
    } = plan;

    const tier = getPlanTierByString(plan.name || '');

    let billingInterval: BillingInterval = BillingInterval.UNKNOWN;
    let pricePerSeatPerInterval: number = price;
    switch (billing_interval_length) {
      case 12:
        billingInterval = BillingInterval.ANNUAL;
        break;
      case 3:
        billingInterval = BillingInterval.QUARTERLY;
        pricePerSeatPerInterval = price / 4;
        break;
      case 1:
        billingInterval = BillingInterval.MONTHLY;
        pricePerSeatPerInterval = price / 12;
        break;
    }

    return {
      pausable,
      isTrial: is_trial,
      custom,
      name,
      id,
      type,
      canSelfDowngrade: can_self_downgrade,
      isSwitchingToTeamPlanADowngrade,
      seats_taken,
      isTrialExpired: is_trial_expired,
      tier,
      billingInterval,
      estimatedRenewalTotal: estimated_renewal_total,
      seatsTotal: total_seats,
      price,
      pricePerSeatPerInterval: pricePerSeatPerInterval,
    };
  }

  public async applyPromoCode(accountId: number, promoCode: string, subscriptionUuid: string): Promise<ApplyPromoCodeApiResponse> {
    const response = await apiClient.post<ApplyPromoCodeApiResponse>(Endpoints.applyPromocode(accountId), {
      couponCode: promoCode,
      subscriptionUuid,
    });
    return response;
  }

  public async updatePlan({ accountId, payload }: UpdatePlanParam): Promise<UpdatePlanApiResponse> {
    try {
      const response = await apiClient.patch<UpdatePlanApiResponse>(Endpoints.updatePlan(accountId), payload);
      return response;
    } catch (error) {
      const err = error as AxiosError;
      const data = err.response?.data ? (err.response.data as { status: string; message: string }) : { status: 'error', message: '' };
      return { status: data.status || 'error', message: data.message };
    }
  }

  public async getCancelReasons(): Promise<CancelReasonsEntity> {
    const cancelReasonsRaw = await apiClient.get<CancelReasonsApiResponse>(Endpoints.getCancelReasons);
    if (cancelReasonsRaw) {
      const formattedReasons: CancelReasonsEntity = cancelReasonsRaw.map((reason) => ({
        id: Number(reason.id),
        label: reason.name,
        notes: reason.notes_label,
        sortOrder: Number(reason.sort_order),
      }));
      return formattedReasons;
    } else {
      return [];
    }
  }

  public async cancelPlan(payload: CancelPlanPayload) {
    try {
      return apiClient.post<CancelPlanApiResponse>(Endpoints.cancelAccountPlan(payload.accountId), {
        accountId: payload.accountId,
        ...payload.cancelReasonPayload,
      });
    } catch (error) {
      const err = error as AxiosError;
      const data = err.response?.data ? (err.response.data as { state: string; message: string }) : { state: 'error', message: '' };
      return { state: data.state || 'error', message: data.message || '' };
    }
  }
}
