import { openNotification } from 'components';
import { closeToast, openToast } from 'components/toast';
import useCreateDocumentMutation from 'hooks/useCreateDocumentMutation';
import { useTranslation } from 'react-i18next';
import { apiDocumentRepository } from 'services/documents/ApiDocumentRepository';
import { Document } from 'services/documents/Document';
import { deleteDocument } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import { useDocumentsContext } from '../../tabs/DocumentsProvider';

export interface UseDuplicateDocumentProps {
  documentToDuplicate: Document;
  viewCreatedDocument: (createdDocumentId: string) => void;
}

export function useDuplicateDocument({ documentToDuplicate, viewCreatedDocument }: UseDuplicateDocumentProps) {
  const { t } = useTranslation();
  const { setDocumentList } = useDocumentsContext();
  const { createDocument } = useCreateDocumentMutation();

  const handleDuplicateUndo = async (duplicatedDocumentId: string) => {
    try {
      await deleteDocument(duplicatedDocumentId);
      setDocumentList((prevDocumentList) => {
        return prevDocumentList.filter(({ id }) => id !== duplicatedDocumentId);
      });
      openToast({
        message: t('pipeline.tabs.document_list.duplicate_undo_success_message'),
      });
    } catch (error) {
      openNotification({
        type: 'error',
        title: t('pipeline.error.undo_error_msg'),
        description: t('pipeline.error.error_description'),
        placement: 'top',
      });
    }
  };

  const handleDuplicateAction = (document: Document) => {
    setDocumentList((documentList) => [document, ...documentList]);
  };

  const duplicateDocument = async () => {
    const documentTitle = documentToDuplicate.title;
    try {
      openToast({
        message: t('pipeline.tabs.document_list.duplicate_started_message', { documentTitle: documentTitle }),
        durationInSeconds: 0,
      });

      const fullDocument = await apiDocumentRepository.getDocumentById(documentToDuplicate.id);
      const { documentId: duplicatedDocumentId } = await createDocument({
        title: `Copy of ${fullDocument.title}`,
        price: fullDocument.price,
        copyFromContentId: fullDocument.contentId,
        titleOfFirstSection: t('editor.default_content_section_title'),
      });
      const fullDuplicatedDocument = await apiDocumentRepository.getDocumentById(duplicatedDocumentId);

      const status = (fullDuplicatedDocument.status[0].toUpperCase() + fullDuplicatedDocument.status.slice(1).toLowerCase()) as
        | 'Won'
        | 'Lost'
        | 'Draft';

      handleDuplicateAction({
        editorVersion: 3,
        id: fullDuplicatedDocument.id,
        title: fullDuplicatedDocument.title,
        status,
        isLocked: fullDuplicatedDocument.isLocked,
        created_at: new Date().toISOString(),
      });

      openToast({
        message: t('pipeline.tabs.document_list.duplicate_done_message', { documentTitle: documentTitle }),
        animationWhenReplacingToast: false,
        onUndo: async () => {
          await handleDuplicateUndo(duplicatedDocumentId);
        },
        onSecondaryAction: {
          text: t('toast.scroll'),
          onClick: () => viewCreatedDocument(duplicatedDocumentId),
        },
      });
    } catch (error) {
      closeToast();
      openNotification({
        type: 'error',
        title: t('pipeline.error.duplicate_error_msg'),
        description: t('pipeline.error.error_description'),
        placement: 'top',
      });
    }
  };

  return { duplicateDocument };
}
