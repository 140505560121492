import { GridBlockType } from 'components/editor/shared/gridBlockType';
import {
  BlocksFromSectionsByDocumentIdApiResponse,
  DocumentSectionsResponse,
  SectionByDocumentIdApiResponse,
} from 'services/documents/DocumentRepository';

export const getTableBlocks = (sectionsResponse: DocumentSectionsResponse): BlocksFromSectionsByDocumentIdApiResponse[][] | undefined => {
  if (!sectionsResponse.sections || !Array.isArray(sectionsResponse.sections)) {
    return undefined;
  }

  return sectionsResponse.sections
    .map((section: SectionByDocumentIdApiResponse) => {
      const tableBlocksInSection = section.blocks.filter((block) => block.type === GridBlockType.TABLE);
      return tableBlocksInSection.length > 0 ? tableBlocksInSection : undefined;
    })
    .filter((blocks) => blocks !== undefined) as BlocksFromSectionsByDocumentIdApiResponse[][];
};
