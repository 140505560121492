import { PreviewFooter } from './footer';
import { PreviewHeader } from './header';
import './preview.styles.less';
import { PreviewMainGrid } from './mainGrid';
import { ReactNode } from 'react';

interface Props {
  header: ReactNode;
  leftAside: ReactNode;
  main: ReactNode;
  footer: ReactNode;
  banner: ReactNode;
}

export const PreviewGrid = ({ header, leftAside, main, footer, banner }: Props) => {
  return (
    <div className="preview">
      <PreviewHeader>{header}</PreviewHeader>
      {banner}
      <div
        className="preview__leftAside"
        style={{
          position: 'fixed',
          left: 0,
          top: '136px',
          width: '240px',
          backgroundColor: 'transparent',
        }}
      >
        {leftAside}
      </div>
      <PreviewMainGrid>{main}</PreviewMainGrid>
      <PreviewFooter>{footer}</PreviewFooter>
    </div>
  );
};
