import { Box, styled, Button } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const StyledArrowButton = styled(Button)({
  height: '0.4rem',
  minWidth: '0.9rem',
  padding: '0px',
  borderRadius: '0px',
  backgroundColor: '#f5f5f5',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
});

const iconStyle = { fontSize: '0.9rem' };

const UpDownArrowIcons = () => {
  return (
    <Box display="flex" flexDirection="column" paddingRight={1} data-testid="up-down-arrow-icons">
      <StyledArrowButton>
        <ArrowDropUpIcon data-testid="arrow-drop-up" style={iconStyle} />
      </StyledArrowButton>
      <StyledArrowButton>
        <ArrowDropDownIcon data-testid="arrow-drop-down" style={iconStyle} />
      </StyledArrowButton>
    </Box>
  );
};

export default UpDownArrowIcons;
