import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FlexDataLinkDataGridProps, FlexDataLink } from '../types';

const filterByDSTypeAndObjectType = (flexDataLinks: FlexDataLink[], dataSourceType: string, objectType: string): FlexDataLink[] => {
  return flexDataLinks.filter((item) => item.dataSourceType === dataSourceType && item.objectType === objectType);
};

const FlexDataLinkDataGrid: React.FC<FlexDataLinkDataGridProps> = ({ parentDataSourceType, parentObjectType, flexDataLinks }) => {
  const linkObjectDataGridColumns: GridColDef[] = [
    {
      field: 'objectLabel',
      headerName: 'Name',
      width: 250,
      editable: true,
    },
  ];

  const filteredRows = filterByDSTypeAndObjectType(flexDataLinks, parentDataSourceType, parentObjectType).map((item) => ({
    id: item.qualifier,
    ...item,
  }));

  return <DataGrid columns={linkObjectDataGridColumns} rows={filteredRows} hideFooter={true} />;
};

export default FlexDataLinkDataGrid;
