import { BlockContent, updateGridDimensionConfig } from 'components/editor/grid/reduxStore/editorSlice';
import { useState } from 'react';
import { useBlockDimensionChangedHandlerWithoutUndoRedo } from 'components/editor/hooks/UseBlockDimensionChangedHandler';
import { useAppDispatch } from 'components/editor/grid/reduxStore/Store';

export function useAspectRatio({
  imageAspectRatioLock,
  blockContent,
  sectionId,
  blockId,
}: {
  imageAspectRatioLock: boolean;
  blockContent: BlockContent | undefined;
  sectionId: string | null;
  blockId: string | null;
}) {
  const { width, height } = blockContent?.blockConfig || { width: 0, height: 0 };
  const [originalAspectRatio] = useState<number | null>(width / height);
  const blockDimensionChangedHandlerWithoutUndoRedo = useBlockDimensionChangedHandlerWithoutUndoRedo();
  const dispatch = useAppDispatch();

  const getRoundedValue = (value: number) => {
    return Number(value.toFixed(5));
  };

  const handleImageDimensionChange = (changedDimension: 'width' | 'height', updatedDimensionValue: number) => {
    if (sectionId && blockId) {
      let newWidth = width;
      let newHeight = height;
      const isWidthChanged = changedDimension === 'width';

      if (imageAspectRatioLock && originalAspectRatio) {
        if (isWidthChanged) {
          newWidth = getRoundedValue(updatedDimensionValue);
          newHeight = getRoundedValue(updatedDimensionValue / originalAspectRatio);
        } else {
          newHeight = getRoundedValue(updatedDimensionValue);
          newWidth = getRoundedValue(updatedDimensionValue * originalAspectRatio);
        }
      } else {
        if (isWidthChanged) {
          newWidth = getRoundedValue(updatedDimensionValue);
        } else {
          newHeight = getRoundedValue(updatedDimensionValue);
        }
      }

      if (sectionId && blockId) {
        dispatch(updateGridDimensionConfig({ sectionId, blockId, width: newWidth, height: newHeight }));
        blockDimensionChangedHandlerWithoutUndoRedo(sectionId, blockId, {
          widthPx: newWidth,
          heightPx: newHeight,
        });
      }
    }
  };

  return { originalAspectRatio, handleImageDimensionChange };
}
