import { Box, useTheme } from '@mui/material';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, Text, XAxis, YAxis } from 'recharts';
import { ViewedSection } from 'services/analytics/AnalyticsRepository';
import { truncateStringToMaxLength } from 'utils/formatStringHandlers';
import { useConvertDuration } from '../../utils/useConvertDuration';

const sectionColors = ['#33C6DA', '#54CFE0', '#8ADEEA', '#B0E9F1', '#E6F8FA'];

export function ViewedSectionsChart({ viewedSections }: { viewedSections: ViewedSection[] }) {
  const theme = useTheme();
  const { convertDuration } = useConvertDuration();

  const sortedDataByViewedTime = [...viewedSections].sort((a, b) => b.viewedTimeInSeconds - a.viewedTimeInSeconds);

  return (
    <Box>
      <ResponsiveContainer width="100%" height={250} data-testid="viewed-sections-chart">
        <BarChart data={sortedDataByViewedTime} layout="vertical" barGap={0} barCategoryGap={0}>
          <XAxis type="number" hide domain={[1, 'dataMax']} scale="log" />
          <YAxis
            type="category"
            dataKey="name"
            tickLine={false}
            axisLine={false}
            tick={(tickProps) => {
              const index = tickProps.index + 1;
              return (
                <Text
                  x={10}
                  y={tickProps.y}
                  textAnchor="middle"
                  dominantBaseline="middle"
                  style={{
                    fill: theme.palette.text.secondary,
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '0.875rem',
                  }}
                >
                  {index}
                </Text>
              );
            }}
            width={30}
          />
          <Bar dataKey="viewedTimeInSeconds" radius={[0, 0, 6, 0]}>
            {viewedSections.map((_, index: number) => (
              <Cell key={`cell-${index}`} fill={sectionColors[index % sectionColors.length]} />
            ))}
            <LabelList
              dataKey="name"
              position="insideLeft"
              content={(contentProps: any) => {
                const rightPadding = 50;
                const leftPadding = 10;
                const availableWidth = contentProps.viewBox.width - (rightPadding + leftPadding);
                const maxVisibleChars = Math.floor(availableWidth / 8);
                return (
                  <Text
                    x={contentProps.x + leftPadding}
                    y={contentProps.y + contentProps.height / 2}
                    width={availableWidth}
                    textAnchor="start"
                    verticalAnchor="middle"
                    style={{
                      fill: theme.palette.text.primary,
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '0.9375rem',
                      fontWeight: 600,
                    }}
                  >
                    {truncateStringToMaxLength(contentProps.value, maxVisibleChars)}
                  </Text>
                );
              }}
            />
            <LabelList
              dataKey="viewedTimeInSeconds"
              position="insideRight"
              formatter={(value: number) => convertDuration(value)}
              style={{
                fill: theme.palette.text.secondary,
                fontFamily: theme.typography.fontFamily,
                fontSize: '0.875rem',
                fontWeight: 400,
              }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}
