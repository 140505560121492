import { createContext, useCallback, useState } from 'react';
import { NotificationSeverity, SiteNotification } from './types';

interface NotificationsProviderProps {
  children: React.ReactNode;
}

type NotificationsContextProps = {
  setNotifications: (notifications: MixedNotificationType[] | MixedNotificationType) => void;
  notifications: SiteNotification[];
};

export type MixedNotificationType = string | SiteNotification;

export const NotificationsContext = createContext<NotificationsContextProps>({} as NotificationsContextProps);

export const NotificationsProvider = ({ children }: NotificationsProviderProps) => {
  const [notifications, setSiteNotifications] = useState<SiteNotification[]>([]);

  const normalizeNotification = useCallback((notification: MixedNotificationType, severity?: NotificationSeverity) => {
    if (typeof notification === 'string') {
      return { content: notification, severity };
    } else {
      return { ...notification, severity: notification.severity || severity };
    }
  }, []);

  const setNotifications = useCallback(
    (notificationContent: MixedNotificationType[] | MixedNotificationType, severity?: NotificationSeverity) => {
      const formattedNotifications = [] as SiteNotification[];
      if (Array.isArray(notificationContent)) {
        notificationContent.map((notification) => {
          formattedNotifications.push(normalizeNotification(notification, severity));
        });
      } else {
        formattedNotifications.push(normalizeNotification(notificationContent, severity));
      }
      setSiteNotifications(formattedNotifications);
    },
    [setSiteNotifications, normalizeNotification]
  );

  const contextValue: NotificationsContextProps = {
    setNotifications,
    notifications,
  };

  return <NotificationsContext.Provider value={contextValue}>{children}</NotificationsContext.Provider>;
};
