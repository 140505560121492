import { useQuery } from 'react-query';
import { AnalyticsResponse } from 'services/analytics/AnalyticsRepository';
import { ApiAnalyticsRepository } from 'services/analytics/ApiAnalyticsRepository';
import { useInsightsContext } from '../InsightsProvider';

const REFRESH_INTERVAL_IN_MS = 60 * 1000;

export function useQueryAnalytics() {
  const { setError, documentId } = useInsightsContext();
  const { data, isLoading } = useQuery<AnalyticsResponse>(
    ['analytics', documentId],
    () => ApiAnalyticsRepository.getAnalyticsByDocumentId(documentId),
    {
      refetchInterval: REFRESH_INTERVAL_IN_MS,
      enabled: !!documentId,
      onError: () => setError('analyticsNotLoaded', true),
    }
  );

  const analytics = !data ? { viewers: [], updatedAt: '', isEmpty: false } : data;

  return {
    analytics,
    isLoading,
  };
}
