import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { UserRolesEntity } from '../interfaces';
import { ApiPermissionsRepository } from '../ApiPermissionsRepository';

type UseUserRolesApiDataParams = {
  accountId: number;
};

type UseUserRolesApiDataResult = {
  data: UserRolesEntity | undefined;
  isLoading: boolean;
  isError: boolean;
  isFetched: boolean;
  isRefetching: boolean;
  refetch;
};

type UseUserRolesApiData<P> = (params: P) => UseUserRolesApiDataResult;

export const useUserRolesApiData: UseUserRolesApiData<UseUserRolesApiDataParams> = ({ accountId }) => {
  const apiPermissionsRepository = new ApiPermissionsRepository();
  const { data, isLoading, isError, isFetched, refetch, isRefetching } = useQuery({
    queryKey: QueryKeys.getUserRoles(accountId),
    queryFn: () => apiPermissionsRepository.getUserRoles(accountId),
  });

  let formattedData: UserRolesEntity | undefined = undefined;
  if (data) {
    formattedData = {
      state: data.state,
      message: data.message,
      roles: [],
    };

    formattedData.roles = data.roles.map((role) => ({
      id: role.id === '' || role.id === null ? null : Number(role.id),
      name: role.name,
      isEditable: role.editable === '1',
    }));
  }

  return {
    data: formattedData,
    isLoading,
    isError,
    isFetched,
    isRefetching,
    refetch,
  };
};
