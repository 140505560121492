import { z } from 'zod';
import { ActivitiesResponse } from './ActivitiesRepository';

const ActivityTypes = {
  TRASHED: 'document-trashed',
  SENT: 'document-sent',
  WON: 'document-won',
  LOST: 'document-lost',
  CREATED: 'document-created',
  SIGNED: 'document-signed',
} as const;

export const validateActivitiesResponse = (response: ActivitiesResponse) => {
  const BaseActivitySchema = z.object({
    type: z.string(),
    createdAt: z.string().datetime(),
  });

  const SimpleActivitySchema = BaseActivitySchema.extend({
    type: z.enum([ActivityTypes.TRASHED, ActivityTypes.SENT, ActivityTypes.WON, ActivityTypes.LOST]),
  });

  const CreatedActivitySchema = BaseActivitySchema.extend({
    type: z.literal(ActivityTypes.CREATED),
    user: z.object({
      name: z.string().min(1),
      id: z.number().positive().int(),
    }),
  });

  const SignedActivitySchema = BaseActivitySchema.extend({
    type: z.literal(ActivityTypes.SIGNED),
    signee: z.object({
      name: z.string().min(1),
      initials: z.string().min(1),
      email: z.string().email(),
    }),
  });

  const ActivitiesResponseSchema = z.object({
    activities: z.array(
      z.union([
        z.discriminatedUnion('type', [SimpleActivitySchema, CreatedActivitySchema, SignedActivitySchema]),
        z.object({
          type: z.string().refine((type) => !Object.values(ActivityTypes).includes(type as never)),
        }),
      ])
    ),
  });

  ActivitiesResponseSchema.parse(response);
};
