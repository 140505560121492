import { useMutation } from 'react-query';
import { ApiSeatsRepository } from 'services/api/seats/ApiSeatsRepository';
import { BasicApiResponse, SeatInviteEntity, SeatInviteMultipleEntity } from 'services/api/seats/interfaces';

type SeatInviteParam = {
  accountId: number;
  payload: SeatInviteEntity | SeatInviteMultipleEntity;
};

export default function useSeatInviteMutation() {
  const reactQuery = useMutation({
    mutationFn: seatInvite,
  });

  return { seatInvite: reactQuery.mutateAsync, seatInvitePromise: reactQuery.mutate, isLoading: reactQuery.isLoading };
}

const seatInvite = async (data: SeatInviteParam): Promise<BasicApiResponse> => {
  const seatsApi = new ApiSeatsRepository();
  const response = await seatsApi.seatInvite(data.payload);

  return {
    state: response.state,
    message: response.message,
  };
};
