import { Endpoints } from 'utils/endpoints';
import { apiClient } from 'services/api';

class TopLevelObjectTypeRepositoryClass {
  public async getTopLevelObjectTypes() {
    return await apiClient.get(Endpoints.getFlexDataTopLevelObjectTypes);
  }
}

export const TopLevelObjectTypeRepository = new TopLevelObjectTypeRepositoryClass();
