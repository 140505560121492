import { Box, Button, Typography } from '@mui/material';
import { COLORS } from '../../muiTheme/MuiDataGrid';
import { SectionByDocumentIdApiResponse } from '../../services/documents/DocumentRepository';
import { useTranslation } from 'react-i18next';
import useTrackActiveSectionInPreview from './useTrackActiveSectionInPreview';

export const PreviewTableOfContents = ({ sections }: { sections?: SectionByDocumentIdApiResponse[] }) => {
  const { t } = useTranslation();
  const { activeSectionId } = useTrackActiveSectionInPreview(sections);

  const activeSectionStyle = {
    color: 'white',
    backgroundColor: COLORS.appFog800,
  };
  const nonActiveSectionStyle = {
    color: COLORS.appFog700,
    backgroundColor: 'transparent',
  };

  return (
    <Box
      sx={{
        marginLeft: '22px',
      }}
      data-testid="preview-table-of-contents"
    >
      <Typography
        sx={{
          color: COLORS.appFog700,
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '24px',
          textTransform: 'uppercase',
        }}
      >
        {t('document.preview.table_of_contents')}
      </Typography>
      {sections?.map((section) => {
        const isActive = activeSectionId === section.id;

        return (
          <Button
            key={section.id}
            className={isActive ? 'active-section' : ''}
            style={{
              display: 'block',
              padding: '4px 8px',
              marginTop: '14px',
              textDecoration: 'none',
            }}
            sx={{
              borderRadius: '4px',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
              textOverflow: 'ellipsis',
              textDecoration: 'none',
              width: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              ...nonActiveSectionStyle,
              '&.active-section': { ...activeSectionStyle },
              '&:hover': { ...activeSectionStyle },
              '&:hover:link': { ...activeSectionStyle },
              '&.active-section:link': { ...activeSectionStyle },
              '&:link': { ...nonActiveSectionStyle },
            }}
            variant="text"
            href={`#${section.id}`}
          >
            {section.title}
          </Button>
        );
      })}
    </Box>
  );
};
