import { useContext } from 'react';
import { TableSettings } from '../../../component';
import SvgIcoTableColumnAddAfter from 'components/icon/icons/IcoTableColumnAddAfter';
import IcoTableColumnAddBefore from 'components/icon/icons/IcoTableColumnAddBefore';
import SvgIcoTrash from 'components/icon/icons/IcoTrash';
import SvgIcoContentCopy from 'components/icon/icons/IcoContentCopy';
import { useTranslation } from 'react-i18next';
import { tableColumnTypes } from '../../../../../../muiTheme/MuiDataGrid';
import { useTableColumnManipulation } from './useTableColumnManipulation';
import { TableColumnAddPositions } from '../types';
import { setActiveTableSettingsPanel } from 'components/editor/grid/reduxStore/blockStyleSettingsSlice';
import { useAppDispatch } from 'components/editor/grid/reduxStore/Store';
import { useTableColumnTypeChange } from './useTableColumnTypeChange';
import { SidePanelContextColumnsPropsType, SidePanelProviderContext } from '../../SidePanelModelsProvider';
import { ColumnTypeIdentifier } from 'components/editor/grid/reduxStore/table.types';

const ColumnSettings = () => {
  const { toggledTableSettingsPanel } = useContext(SidePanelProviderContext) as SidePanelContextColumnsPropsType;
  const dispatch = useAppDispatch();
  const { handleColumnTypeChange, columnType } = useTableColumnTypeChange();
  const { t } = useTranslation();
  const { handleColumnAdd, handleColumnDuplication, handleColumnDeletion, isPriceColumnExists, isSubtotalColumnExists } =
    useTableColumnManipulation();

  const columnSelectedModel = toggledTableSettingsPanel?.tableApi.selectedModel;
  const activeModelColumnType = columnSelectedModel?.colDef?.columnType as string;
  const activeColumnKey = activeModelColumnType?.toUpperCase() as string;
  const columnTypeValue = tableColumnTypes[activeColumnKey];
  const disabledOnOptional = activeModelColumnType === ColumnTypeIdentifier.OPTIONAL;

  const handleBackButtonClick = () => {
    dispatch(setActiveTableSettingsPanel({ type: null }));
  };

  const selectOptions = (option: string): boolean => {
    if (isSubtotalColumnExists && option === ColumnTypeIdentifier.SUBTOTAL) {
      return true;
    }
    return isPriceColumnExists && option === ColumnTypeIdentifier.PRICE;
  };

  const shouldDisableDuplicateColumn = selectOptions(columnTypeValue);
  const shouldDisableAddAfterColumn = isSubtotalColumnExists && columnTypeValue === ColumnTypeIdentifier.SUBTOTAL;

  const buttons = [
    {
      icon: <SvgIcoTableColumnAddAfter />,
      arialLabel: 'column-add-after',
      disabled: shouldDisableAddAfterColumn,
      fn: () => handleColumnAdd(TableColumnAddPositions.AFTER),
    },
    {
      icon: <IcoTableColumnAddBefore />,
      arialLabel: 'column-add-before',
      disabled: disabledOnOptional,
      fn: () => handleColumnAdd(TableColumnAddPositions.BEFORE),
    },
    {
      icon: <SvgIcoContentCopy />,
      disabled: shouldDisableDuplicateColumn || disabledOnOptional,
      arialLabel: 'content-copy',
      fn: handleColumnDuplication,
    },
    { icon: <SvgIcoTrash />, disabled: disabledOnOptional, arialLabel: 'trash', fn: handleColumnDeletion },
  ];

  return (
    <TableSettings
      iconButtons={buttons}
      handleBackButtonClick={handleBackButtonClick}
      handleSelectChange={handleColumnTypeChange}
      helpText={t('document.grid.table.setting.update_select_column_type')}
      panelTitle={t('document.grid.table.setting.column_type')}
      selectId="select-column-type"
      selectOptions={tableColumnTypes}
      checkDisabled={selectOptions}
      selectValue={columnType}
      menuTitle={t('document.grid.table.setting.columns')}
      testId="table-column-settings"
      disabled={disabledOnOptional}
    />
  );
};

export default ColumnSettings;
